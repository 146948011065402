import { createReducer } from "@reduxjs/toolkit";

import { State } from "store";

export type LoadingState = { [key: string]: boolean };

const loading = createReducer({} as LoadingState, (builder) => {
  builder
    .addMatcher(
      (action) => action.type.endsWith("/pending"),
      (state, action) => {
        const type = action.type.split("/")[0];
        state[type] = true;
      }
    )
    .addMatcher(
      (action) =>
        action.type.endsWith("/fulfilled") || action.type.endsWith("/rejected"),
      (state, action) => {
        const type = action.type.split("/")[0];
        state[type] = false;
      }
    );
});

export const loadingSelector = (actionType: string) => (state: State) =>
  state.loading[actionType];

export default loading;
