import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";

import flopLogo2 from "assets/images/flop_gto_logo_2.svg";
import SignupForm from "components/SignupForm";
import UnloggedWrapper from "components/UnloggedWrapper";
import ButtonRounded from "ui/ButtonRounded";
import Text from "ui/Text";
import { COLORS } from "utils/theme";

const useStyles = makeStyles({
  card: {
    borderRadius: "16px",
  },
  typoRoundedText: {
    fontFamily: "Typo Round Regular",
  },
  welcomeWrapper: {
    background: "linear-gradient(153.33deg, #F5AA52 0%, #FE5F55 100%)",
  },
});

export default function Signup({ createAccount, onBack }: any) {
  const classes = useStyles();

  return (
    <UnloggedWrapper>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgcolor={COLORS.SECONDARY}
      >
        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
          <img src={flopLogo2} alt="flop gto logo" width={180} />
          <Text
            color={COLORS.WHITE}
            fontFamily="Typo Round Regular"
            fontSize="24px"
          >
            (2/2) Create account
          </Text>
        </Box>
        <Box mb={3}>
          <Card className={classes.card} raised>
            <SignupForm createAccount={createAccount} />
          </Card>
        </Box>
        <Box width="fit-content">
          <ButtonRounded
            label="Back to plan selection"
            borderColor={COLORS.BUTTON_PRIMARY}
            color={COLORS.TRANSPARENT}
            labelColor={COLORS.BUTTON_PRIMARY}
            onClick={onBack}
          />
        </Box>
      </Box>
    </UnloggedWrapper>
  );
}
