import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";

import { COLORS } from "utils/theme";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const useStyles = makeStyles({
  floatingButton: {
    position: "fixed",
    bottom: 25,
    right: 50,
  },
  fab: {
    backgroundColor: COLORS.ACCENT_1,
  },
});

type Props = {
  containerElement: HTMLElement;
};

const ScrollTop: React.FC<Props> = ({ containerElement }) => {
  const classes = useStyles();

  const trigger = useScrollTrigger({
    target: containerElement,
    threshold: 100,
  });

  const handleClick = (event: any) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.floatingButton}
      >
        <Fab
          className={classes.fab}
          size="medium"
          aria-label="scroll back to top"
        >
          <ArrowUpwardIcon style={{ color: "#FFFFFF" }} />
        </Fab>
      </div>
    </Zoom>
  );
};

export default ScrollTop;
