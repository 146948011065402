export const COLORS = {
  ACCENT_1: "#36D1CF",
  ACCENT_2: "#BA57CE",
  ACCENT_3: "#4D8DCC",
  DESATURED_ACCENT_1: "#AAE4E4",
  BACKGROUND_1: "#F7F8FD",
  BACKGROUND_2: "#E9F5F5",
  BLACK: "#000",
  BLUE_1: "#133556",
  BLUE_2: "#43678A",
  BLUE_3: "#7694B3",
  BLUE_4: "#D2D9DF",
  BLUE_5: "#F4F7F9",
  BLUE_6: "#B3C0CD",
  BUTTON_PRIMARY: "#FE5F55",
  ERROR: "#FE5F55",
  GREEN: "#7CC194",
  MUTED_PRIMARY: "#8A9BA8",
  PRIMARY: "#323F48",
  PRIMARY_OPACITY_50: "#323F4880",
  SECONDARY: "#1A2025",
  WHITE: "#FFF",
  GREY_BLUE: "#26333D",
  SECONDARY_TEXT: "#7694b2",
  TITLE_1: "#818181",
  TRANSPARENT: 'transparent',
  WARNING: '#F18B2D',
};

export type ColorVariants = "default" | "pio" | "michael" | "blue";

export const COLOR_VARIANTS: { label: string; value: ColorVariants }[] = [
  { label: "Theme 1", value: "default" },
  { label: "Theme 2", value: "pio" },
  { label: "Theme 3", value: "michael" },
  { label: "Theme 4", value: "blue" },
];

type ActionColors = {
  [variant in ColorVariants]: { [key: string]: string | string[] };
};

export const ACTIONS_COLORS: ActionColors = {
  default: {
    ALL_IN: "#4B1226",
    CALL: "#64B6B5",
    CHECK: "#64B6B5",
    FOLD: "#31728F",
    RAISES: ["#82304C", "#D15057", "#E67E6C", "#F8A06B"],
  },
  pio: {
    ALL_IN: "#4B1226",
    CALL: "#8FBC8B",
    CHECK: "#8FBC8B",
    FOLD: "#6DA2C0",
    RAISES: ["#AE5747", "#C16D59", "#DA886E", "#E9967A"],
  },
  michael: {
    ALL_IN: "#5A1D25",
    CALL: "#2DB2AF",
    CHECK: "#2DB2AF",
    FOLD: "#034F9D",
    RAISES: ["#83304C", "#D14F57", "#EA8676", "#FDA671"],
  },
  blue: {
    ALL_IN: "#132234",
    CALL: "#61BBA0",
    CHECK: "#61BBA0",
    FOLD: "#616D72",
    RAISES: ["#1D3244", "#345468", "#446D82", "#54879D"],
  },
};

export const getActionColor = (variant: ColorVariants, action: string) =>
  action === "FOLD"
    ? ACTIONS_COLORS[variant].FOLD
    : action === "CALL"
    ? ACTIONS_COLORS[variant].CALL
    : action === "CHECK"
    ? ACTIONS_COLORS[variant].CHECK
    : ACTIONS_COLORS[variant].ALL_IN;

export const getRaiseActionColor = (
  variant: ColorVariants,
  action: string,
  raiseActions: string[]
) => {
  const raiseActionIndex = raiseActions.findIndex(
    (raiseAction) => raiseAction === action
  );
  const raiseActionsCount = raiseActions.length - 1;
  const raiseColors = ACTIONS_COLORS[variant]["RAISES"];
  if (raiseActionsCount && raiseActionIndex === raiseActionsCount) {
    return raiseColors[raiseColors.length - 1];
  } else {
    return raiseColors[raiseActionIndex];
  }
};

export const RAISE_ACTIONS_REGEX = /RAISE|POT/;

export const getActionLabel = (action: string, actionValueInBb: number) =>
  action.search(RAISE_ACTIONS_REGEX) !== -1
    ? `RAISE ${actionValueInBb} bb`
    : action;
