import React, { useCallback, useState } from "react";
import { upperFirst } from "lodash/fp";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";

import Text from "ui/Text";
import HANDS_MATRIX from "utils/hands";
import { Hand, StrategyOverview } from "utils/models";
import {
  COLORS,
  ColorVariants,
  getActionColor,
  getActionLabel,
  getRaiseActionColor,
  RAISE_ACTIONS_REGEX,
} from "utils/theme";

import cardsBlackPng from "assets/icons/cards_black.png";

type HandCellProps = {
  colorVariant: ColorVariants;
  displayWeights: boolean;
  hand: Hand;
  handGroup: string;
  isFirstX: boolean;
  isFirstY: boolean;
  isLastX: boolean;
  isLastY: boolean;
  raiseStrategies: string[];
  strategiesOverview: StrategyOverview[];
};

const HandCell: React.FC<HandCellProps> = ({
  colorVariant,
  displayWeights,
  hand,
  handGroup,
  isFirstX,
  isFirstY,
  isLastX,
  isLastY,
  raiseStrategies,
  strategiesOverview,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getStrategyActionLabel = useCallback(
    (action: string) => {
      const strategy = strategiesOverview.find(
        (strategy) => strategy.action === action
      );
      return strategy
        ? upperFirst(
            getActionLabel(
              strategy.action,
              strategy.actionValueInBb
            ).toLowerCase()
          )
        : "";
    },
    [strategiesOverview]
  );

  return (
    <>
      <Box
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          alignItems: "center",
          ...(isFirstY && isFirstX && { borderTopLeftRadius: "0.4vw" }),
          ...(isFirstY && isLastX && { borderTopRightRadius: "0.4vw" }),
          ...(isLastY && isFirstX && { borderBottomLeftRadius: "0.4vw" }),
          ...(isLastY && isLastX && { borderBottomRightRadius: "0.4vw" }),
          ...(!isLastY && {
            borderBottom: `2px solid ${COLORS.PRIMARY}`,
          }),
          ...(!isLastX && {
            borderRight: `2px solid ${COLORS.PRIMARY}`,
          }),
          bgcolor: "#384B59",
          display: "flex",
          flex: 1,
          height: "2vw",
          justifyContent: "center",
          position: "relative",
          width: "2vw",
        }}
      >
        <Box
          sx={{
            bottom: 0,
            display: "flex",
            height: `${displayWeights && hand ? hand.handGroupWeight : "100"}%`,
            position: "absolute",
            width: "100%",
            zIndex: 0,
          }}
        >
          {hand?.actionsStrategies.map(({ action, strategy }, index) => (
            <Box
              key={`strategy-${action}-${strategy}`}
              sx={{
                ...(isFirstY &&
                  isFirstX &&
                  index === 0 && { borderTopLeftRadius: "0.4vw" }),
                ...(isFirstY &&
                  isLastX &&
                  index === hand.actionsStrategies.length - 1 && {
                    borderTopRightRadius: "0.4vw",
                  }),
                ...(isLastY &&
                  isFirstX &&
                  index === 0 && { borderBottomLeftRadius: "0.4vw" }),
                ...(isLastY &&
                  isLastX &&
                  index === hand.actionsStrategies.length - 1 && {
                    borderBottomRightRadius: "0.4vw",
                  }),
                bgcolor:
                  action.search(RAISE_ACTIONS_REGEX) !== -1
                    ? getRaiseActionColor(colorVariant, action, raiseStrategies)
                    : getActionColor(colorVariant, action),
                width: `${strategy * 100}%`,
              }}
            />
          ))}
        </Box>
        <Box sx={{ zIndex: 1 }}>
          <Text
            color={
              hand?.actionsStrategies.find(({ strategy }) => strategy > 0)
                ? COLORS.WHITE
                : "#3B5364"
            }
            fontSize="0.85vw"
            fontWeight="bold"
          >
            {handGroup}
          </Text>
        </Box>
      </Box>
      {hand && (
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
          style={{ pointerEvents: "none" }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              p: "8px",
            }}
          >
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <img alt="" src={cardsBlackPng} style={{ marginRight: "4px" }} />
              <Text color={COLORS.SECONDARY} fontWeight="bold">
                {hand.handGroup}
              </Text>
            </Box>
            {hand.actionsStrategies.map(({ action, ev, strategy }) => (
              <Box
                key={`popover-${action}-${strategy}`}
                sx={{ display: "flex", px: "0.6vw", py: "0.2vw" }}
              >
                <Text color={COLORS.BLUE_3} fontWeight="bold" fontSize="0.75vw">
                  {getStrategyActionLabel(action)}:
                </Text>
                &nbsp;
                <Text color={COLORS.SECONDARY} fontSize="0.75vw">
                  {(strategy * 100).toFixed(0)}%
                </Text>
                &nbsp;
                <Text color={COLORS.BLUE_3} fontSize="0.75vw">
                  |
                </Text>
                &nbsp;
                <Text color={COLORS.BLUE_3} fontSize="0.75vw" fontWeight="bold">
                  EV:
                </Text>
                &nbsp;
                <Text color={COLORS.SECONDARY} fontSize="0.75vw">
                  {(ev / 100.0).toFixed(2)} bb
                </Text>
              </Box>
            ))}
          </Box>
        </Popover>
      )}
    </>
  );
};

type Props = {
  colorVariant: ColorVariants;
  displayWeights: boolean;
  hands: Hand[];
  raiseStrategies: string[];
  strategiesOverview: StrategyOverview[];
};

const HandStrategy: React.FC<Props> = ({
  colorVariant,
  displayWeights,
  hands,
  raiseStrategies,
  strategiesOverview,
}) => {
  const getHand = (hand: string) =>
    hands.find(({ handGroup }) => handGroup === hand)!;

  return (
    <Box
      className="hand-strategy-tour"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      {HANDS_MATRIX.map((row, i) => (
        <Box key={`hand-row-${i}`} sx={{ display: "flex", flex: 1 }}>
          {row.map((cell, j) => (
            <HandCell
              colorVariant={colorVariant}
              displayWeights={displayWeights}
              hand={getHand(cell)}
              handGroup={cell}
              key={`hand-${cell}`}
              isFirstX={j === 0}
              isFirstY={i === 0}
              isLastX={j === row.length - 1}
              isLastY={i === HANDS_MATRIX.length - 1}
              raiseStrategies={raiseStrategies}
              strategiesOverview={strategiesOverview}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default HandStrategy;
