import React from "react";
import classNames from "classnames";
import { Box, Radio } from "@material-ui/core";
import Text from "ui/Text";
import * as css from "./PlanListItem.css";
import { COLORS } from "../../../utils/theme";

export const dataTestIds = {
  root: "TODO",
};

export interface PlanListItemProps {
  description: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  name: string;
  onSelect: any;
  price: string;
}

export default function PlanListItem({
  description,
  isDisabled,
  isSelected,
  name,
  onSelect,
  price,
}: PlanListItemProps) {
  return (
    <Box
      display="flex"
      flex={1}
      p="0.5em 0.75em 0.5em 0.25em"
      borderRadius={2}
      bgcolor={isSelected ? COLORS.BACKGROUND_2 : COLORS.BACKGROUND_1}
    >
      <Box>
        <Radio
          id={`plan-${name}`}
          checked={isSelected}
          disabled={isDisabled}
          color="default"
          className={classNames({ [css.selectedRadio]: isSelected })}
          onChange={onSelect}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        <Box display="flex" justifyContent="space-between">
          <label htmlFor={`plan-${name}`} style={{ cursor: "pointer" }}>
            <Text color={COLORS.BLUE_1}>{name}</Text>
          </label>
          <Text color={COLORS.BLUE_1}>{price}</Text>
        </Box>
        <Text variant="subtitle2" color={COLORS.SECONDARY_TEXT}>
          {description}
        </Text>
      </Box>
    </Box>
  );
}
