import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBack from "@material-ui/icons/ArrowBack";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Menu from "@material-ui/icons/Menu";

import { logout } from "store/account";
import Text from "ui/Text";
import TextLink from "ui/TextLink";
import { COLORS } from "utils/theme";

import cardsPng from "assets/icons/cards.png";
import cogPng from "assets/icons/cog.png";
import goOutPng from "assets/icons/go_out.png";
import mailPng from "assets/icons/mail.png";
import flopGTOLogoSvg from "assets/images/flop_gto_logo.svg";
import { CONTACT_US } from "../constants/routes";

type MenuItem = {
  color?: string;
  icon: string;
  label: string;
  path: string;
};

const menuItems: MenuItem[] = [
  {
    icon: cardsPng,
    label: "GTO Solver",
    path: "/",
  },
  {
    icon: cogPng,
    label: "Account & Settings",
    path: "/settings",
  },
  {
    icon: mailPng,
    label: "Contact Us",
    path: CONTACT_US,
  },
];

type DrawerItemProps = {
  color?: string;
  icon: string;
  isActive?: boolean;
  label: string;
};

const DrawerItem: React.FC<DrawerItemProps> = ({
  color = COLORS.WHITE,
  icon,
  isActive = false,
  label,
}) => (
  <Box
    sx={{
      alignItems: "center",
      ...(isActive
        ? { backgroundColor: COLORS.BLUE_3 }
        : {
            cursor: "pointer",
            ":hover": {
              backgroundColor: COLORS.BLUE_6,
            },
          }),
      color,
      display: "flex",
      px: "1.6vw",
      py: "0.6vw",
    }}
  >
    <img
      alt=""
      src={icon}
      style={{ height: "1.25vw", marginRight: "0.4vw", width: "1.25vw" }}
    />
    <Text fontSize="0.93vw" {...(isActive && { fontWeight: "bold" })}>
      {label}
    </Text>
  </Box>
);

type Props = {
  openGTOTour: () => void;
};

const useDrawerStyles = makeStyles({
  root: {
    backgroundColor: COLORS.WHITE,
    zIndex: 2500,
  },
  paper: {
    backgroundColor: "#454F57",
    marginTop: "2.86vw",
    width: "13.5vw",
  },
});

const Navbar: React.FC<Props> = ({ openGTOTour }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const drawerClasses = useDrawerStyles();

  const drawerRef = useRef(null);
  const menuTogglerRef = useRef(null);

  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const handleClickOutsideDrawer = useCallback(
    ({ target }) => {
      if (
        drawerRef.current &&
        menuTogglerRef.current &&
        !(drawerRef.current! as HTMLElement).contains(target) &&
        !(menuTogglerRef.current! as HTMLElement).contains(target)
      ) {
        setIsDrawerOpened(false);
      }
    },
    [drawerRef, menuTogglerRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDrawer);
    return () =>
      document.removeEventListener("mousedown", handleClickOutsideDrawer);
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: "#0E1011",
          boxShadow:
            "0px 2px 4px rgba(0, 0, 0, 0.15), 0px 4px 5px rgba(0, 0, 0, 0.15)",
          display: "flex",
          justifyContent: "center",
          py: "0.6vw",
          zIndex: 50,
        }}
      >
        <Box
          onClick={() => setIsDrawerOpened(!isDrawerOpened)}
          ref={menuTogglerRef}
          sx={{
            cursor: "pointer",
            left: "1vw",
            position: "absolute",
            top: "0.8vw",
          }}
        >
          <Menu style={{ color: COLORS.WHITE, fontSize: "1.25vw" }} />
        </Box>
        <img
          alt="Flop GTO logo"
          src={flopGTOLogoSvg}
          style={{ width: "7.5vw" }}
        />
        {pathname === "/" && (
          <Box
            onClick={openGTOTour}
            sx={{
              cursor: "pointer",
              position: "absolute",
              right: "12px",
              top: "16px",
            }}
          >
            <HelpOutline style={{ color: COLORS.WHITE }} />
          </Box>
        )}
      </Box>
      <Drawer
        classes={drawerClasses}
        open={isDrawerOpened}
        ref={drawerRef}
        variant="persistent"
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            onClick={() => setIsDrawerOpened(false)}
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "flex-end",
              p: "0.6vw",
            }}
          >
            <ArrowBack style={{ fontSize: "1.25vw" }} />
          </Box>
          {menuItems.map(({ icon, label, path }) => (
            <Link
              component={RouterLink}
              key={`drawer-link-${label}`}
              to={path}
              underline="none"
            >
              <DrawerItem
                icon={icon}
                isActive={pathname === path}
                label={label}
              />
            </Link>
          ))}
          <Box onClick={() => dispatch(logout())}>
            <DrawerItem color={COLORS.ERROR} icon={goOutPng} label="Log out" />
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            position: "absolute",
            bottom: "2.86vw",
            mx: "auto",
            left: 0,
            right: 0,
          }}
          mb={1}
        >
          <Link
            component={RouterLink}
            to={{
              pathname: "/cgu",
              state: { fromLogin: false },
            }}
          >
            <TextLink color={COLORS.WHITE}>Terms &amp; Conditions</TextLink>
          </Link>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
