import React from "react";
import CSS from "csstype";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Variant } from "@material-ui/core/styles/createTypography";

type Props = {
  component?: React.ElementType;
  color?: string;
  fontFamily?: CSS.Property.FontFamily;
  fontSize?: CSS.Property.FontSize;
  fontWeight?: CSS.Property.FontWeight;
  pointer?: boolean;
  variant?: Variant;
  whiteSpace?: CSS.Property.WhiteSpace;
};

type TypographyStyle = {
  color?: string;
  fontFamily?: CSS.Property.FontFamily;
  fontSize?: CSS.Property.FontSize;
  fontWeight?: CSS.Property.FontWeight;
  pointer?: boolean;
  whiteSpace?: CSS.Property.WhiteSpace;
};

const useStyles = makeStyles({
  root: ({
    color,
    fontFamily,
    fontSize,
    fontWeight,
    pointer,
    whiteSpace,
  }: TypographyStyle) => ({
    color,
    ...(fontFamily && {
      fontFamily,
    }),
    ...(fontSize && {
      fontSize,
    }),
    ...(fontWeight && {
      fontWeight,
    }),
    ...(pointer && {
      cursor: "pointer",
    }),
    ...(whiteSpace && {
      whiteSpace,
    }),
  }),
});

const Text: React.FC<Props> = ({
  children,
  color,
  component,
  fontFamily,
  fontSize,
  fontWeight,
  pointer,
  variant,
  whiteSpace,
}) => {
  const classes = useStyles({
    color,
    fontFamily,
    fontSize,
    fontWeight,
    pointer,
    whiteSpace,
  });
  return (
    <Typography
      classes={classes}
      {...(component && { component })}
      variant={variant}
    >
      {children}
    </Typography>
  );
};

export default Text;
