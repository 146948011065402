import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from "@material-ui/core";
import API from "api";
import { accountSelector, fetchProfile, logout } from "store/account";
import ButtonRounded from "ui/ButtonRounded";
import Modal from "ui/Modal";
import Text from "ui/Text";
import TextFieldLight from "ui/TextFieldLight";
import TextLink from "ui/TextLink";
import { COLORS } from "utils/theme";
import circledCheck from "assets/icons/circled_check.svg";
import { PlanType } from "types";

export interface SignupCompletionProps {}

export default function SignupCompletion() {
  const dispatch = useDispatch();
  const [isEmailModificationOpen, setEmailModificationOpen] = useState(false);
  const [isSending, setSending] = useState(false);
  const [newActivationEmail, setNewActivationEmail] = useState("");
  const [error, setError] = useState("");

  const { email, userId, originalChosenPackage } = useSelector(accountSelector);

  function openEmailModificationModal() {
    setEmailModificationOpen(true);
  }

  function closeEmailModificationModal() {
    setEmailModificationOpen(false);
  }

  async function resendActivationEmail() {
    setSending(true);
    await API.resendActivationEmail({ email, userId });
    setSending(false);
  }

  async function updateActivationEmail() {
    closeEmailModificationModal();

    try {
      setSending(true);
      await API.updateProfile(userId, newActivationEmail);
      await dispatch(fetchProfile(userId));
    } catch (e) {
      if (e.errorCode === "ERR_ACCOUNT_002") {
        setError(
          `The provided email (${newActivationEmail}) is already in use by another player. Please try another email.`
        );
      }
    } finally {
      setSending(false);
    }
  }

  return (
    <>
      <Modal
        onClose={closeEmailModificationModal}
        onSubmit={updateActivationEmail}
        open={isEmailModificationOpen}
        submitLabel="Use this email"
        title="Change email address"
      >
        <Box mb={2}>
          <Text color={COLORS.BLUE_1}>
            Please, write the email adress you want to use below.
          </Text>
        </Box>
        <TextFieldLight
          label="Email address"
          onChange={setNewActivationEmail}
          value={newActivationEmail}
        />
      </Modal>
      <Box
        bgcolor={COLORS.SECONDARY}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
      >
        <Box borderRadius="16px" maxWidth={500} mb={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="90px"
            borderRadius="16px 16px 0 0"
            style={{
              background: "linear-gradient(90deg, #F5AA52 0%, #FE5F55 100%)",
            }}
          >
            <Box mr={1}>
              <img src={circledCheck} alt="complete icon" />
            </Box>
            <Text color={COLORS.WHITE} fontSize="24px">
              You’re almost done!
            </Text>
          </Box>
          <Box
            bgcolor={COLORS.PRIMARY}
            position="relative"
            borderRadius="0 0 16px 16px"
          >
            {isSending && (
              <Box
                position="absolute"
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor={COLORS.PRIMARY}
                borderRadius="0 0 16px 16px"
                style={{ opacity: 0.9 }}
              >
                <CircularProgress
                  thickness={2}
                  style={{ color: COLORS.ERROR }}
                />
              </Box>
            )}
            {error && (
              <Box
                position="absolute"
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                bgcolor={COLORS.PRIMARY}
                p={4}
                borderRadius="0 0 16px 16px"
              >
                <Box mb={2}>
                  <Text color={COLORS.WHITE}>{error}</Text>
                </Box>
                <Box width="fit-content">
                  <ButtonRounded
                    label="Close"
                    borderColor={COLORS.ERROR}
                    labelColor={COLORS.ERROR}
                    onClick={() => setError("")}
                  />
                </Box>
              </Box>
            )}
            <Box p={3} borderRadius="0 0 16px 16px">
              <Box mb={2}>
                <Box mb={1}>
                  <Text color={COLORS.WHITE}>We just send a new email to:</Text>
                </Box>
                <Box textAlign="center">
                  <Text color={COLORS.WHITE} fontWeight={600}>
                    {email}
                  </Text>
                </Box>
              </Box>
              <Box mb={2}>
                <Text color={COLORS.WHITE}>
                  Please, use the link provided in this email to confirm you
                  account.
                  {originalChosenPackage === PlanType.PREMIUM &&
                    " You will then be able to proceed to checkout."}
                </Text>
              </Box>
              <Box mb={2}>
                <Text color={COLORS.WHITE}>
                  Click here if you have not received anything and want us to{" "}
                  <span onClick={resendActivationEmail}>
                    <TextLink color={COLORS.WARNING}>send a new email</TextLink>
                  </span>
                </Text>
              </Box>
              <Box>
                <Text color={COLORS.WHITE}>
                  If you have entered a wrong email when creating your account,
                  please click here to{" "}
                  <Box
                    component="span"
                    display="inline-block"
                    onClick={openEmailModificationModal}
                  >
                    <TextLink color={COLORS.WARNING}>
                      change email address
                    </TextLink>
                  </Box>
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box width="fit-content">
          <ButtonRounded
            label="Log out"
            borderColor={COLORS.BUTTON_PRIMARY}
            color={COLORS.TRANSPARENT}
            labelColor={COLORS.BUTTON_PRIMARY}
            onClick={() => dispatch(logout())}
          />
        </Box>
      </Box>
    </>
  );
}
