import React from "react";

import GTOLarge from "./GTO_large";

type Props = {
  closeGTOTour: () => void;
  isGTOTourOpened: boolean;
};

const GTO: React.FC<Props> = ({ closeGTOTour, isGTOTourOpened }) => {
  return (
    <GTOLarge closeGTOTour={closeGTOTour} isGTOTourOpened={isGTOTourOpened} />
  );
};

export default GTO;
