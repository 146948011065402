import React from "react";
import Box from "@material-ui/core/Box";

import Text from "ui/Text";
import { StrategyOverview } from "utils/models";
import {
  COLORS,
  ColorVariants,
  getActionColor,
  getActionLabel,
  getRaiseActionColor,
  RAISE_ACTIONS_REGEX,
} from "utils/theme";

type Props = {
  colorVariant: ColorVariants;
  raiseStrategies: string[];
  strategy: StrategyOverview;
};

const StrategyOverviewCard: React.FC<Props> = ({
  colorVariant,
  raiseStrategies,
  strategy: { action, actionValueInBb, combosCount, rangeFrequency },
}) => {
  const color =
    action.search(RAISE_ACTIONS_REGEX) !== -1
      ? getRaiseActionColor(colorVariant, action, raiseStrategies)
      : getActionColor(colorVariant, action);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        m: "0.4vw",
        width: "6.9vw",
      }}
    >
      <Box
        sx={{
          borderTopLeftRadius: "0.4vw",
          borderTopRightRadius: "0.4vw",
          bgcolor: color,
          py: "0.2vw",
          textAlign: "center",
        }}
      >
        <Text color={COLORS.WHITE} fontSize="0.85vw" fontWeight="bold">
          {getActionLabel(action, actionValueInBb)}
        </Text>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          borderBottomLeftRadius: "0.4vw",
          borderBottomRightRadius: "0.4vw",
          bgcolor: COLORS.SECONDARY,
          color: COLORS.WHITE,
          display: "flex",
          flexDirection: "column",
          px: "0.7vw",
          py: "0.3vw",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            mb: "0.3vw",
          }}
        >
          <Text fontSize="0.85vw" fontWeight="bold">
            {rangeFrequency}%
          </Text>
          &nbsp;
          <Text fontSize="0.65vw">frequency</Text>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Text fontSize="0.65vw">{combosCount}&nbsp; combos</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default StrategyOverviewCard;
