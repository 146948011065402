import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Variant } from "@material-ui/core/styles/createTypography";

type Props = {
  color?: string;
  variant?: Variant;
};

type TypographyStyle = {
  color?: string;
};

const useStyles = makeStyles({
  root: ({ color }: TypographyStyle) => ({
    color,
    cursor: "pointer",
    textDecoration: "underline",
  }),
});

const TextLink: React.FC<Props> = ({ children, color, variant }) => {
  const classes = useStyles({ color });
  return (
    <Typography classes={classes} component="span" variant={variant}>
      {children}
    </Typography>
  );
};

export default TextLink;
