import { createReducer } from "@reduxjs/toolkit";

import { State } from "store";

export type ErrorState = { [key: string]: { code: string; message: string } };

const error = createReducer({} as ErrorState, (builder) => {
  builder
    .addMatcher(
      (action) => action.type.endsWith("/pending"),
      (state, action) => {
        const type = action.type.split("/")[0];
        delete state[type];
      }
    )
    .addMatcher(
      (action) => action.type.endsWith("/rejected"),
      (state, action) => {
        const type = action.type.split("/")[0];
        state[type] = {
          code: action.error.code,
          message: action.error.message,
        };
      }
    );
});

export const errorSelector = (actionType: string) => (state: State) =>
  state.error[actionType];

export const errorMessageSelector = (actionType: string) => (state: State) =>
  state.error[actionType]?.message;

export default error;
