import React, { useCallback, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";

import API from "api";
import Modal from "ui/Modal";
import Text from "ui/Text";
import TextFieldLight from "ui/TextFieldLight";
import TextLink from "ui/TextLink";
import { COLORS } from "utils/theme";
import { isEmail, isRequired, validator } from "utils";

type Props = {
  textColor?: string;
};

const PasswordForgot: React.FC<Props> = ({ textColor = COLORS.WHITE }) => {
  const [isPasswordForgotModalOpen, setIsPasswordForgotModalOpen] = useState(
    false
  );
  const [isRequestSent, setIsRequestSent] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  useEffect(() => {
    if (emailError) {
      setEmailError("");
    }
  }, [email]);

  const isEmailValid = useCallback(() => {
    const emailError = validator([isRequired, isEmail])(email);
    if (emailError) {
      setEmailError(emailError);
    }
  }, [email]);

  const closeModal = useCallback(() => {
    setIsPasswordForgotModalOpen(false);
    setEmail("");
    setEmailError("");
    setIsRequestSent(false);
  }, []);

  const onResetPasswordRequest = useCallback(() => {
    const postResetPasswordRequest = async () => {
      try {
        await API.postResetPasswordRequest(email);
        setIsRequestSent(true);
      } catch (e) {
        setEmailError(e.message);
      }
    };
    postResetPasswordRequest();
  }, [email]);

  return (
    <>
      <Box onClick={() => setIsPasswordForgotModalOpen(true)}>
        <TextLink color={textColor} variant="body2">
          I forgot my password
        </TextLink>
      </Box>

      {isRequestSent ? (
        <Modal
          closeLabel="Ok, got it!"
          onClose={closeModal}
          open={isPasswordForgotModalOpen}
          title="Email sent"
        >
          <Text color={COLORS.PRIMARY}>
            We just sent you an email to reset your password.
          </Text>
          <Text color={COLORS.PRIMARY}>
            Please, check your mail box and use the provided link to set your
            new password.
          </Text>
        </Modal>
      ) : (
        <Modal
          onClose={closeModal}
          onSubmit={onResetPasswordRequest}
          open={isPasswordForgotModalOpen}
          submitDisabled={!email || !!emailError}
          submitLabel="Send me an email"
          title="Reset your password"
        >
          <Box sx={{ mb: "32px" }}>
            <Text color={COLORS.PRIMARY}>
              Please, enter your account email address below and we will send
              you an email to reset your password.
            </Text>
          </Box>
          <TextFieldLight
            error={emailError}
            label="Email address"
            onBlur={isEmailValid}
            onChange={setEmail}
            value={email}
          />
        </Modal>
      )}
    </>
  );
};

export default PasswordForgot;
