import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { errorMessageSelector } from "store/error";
import { loadingSelector } from "store/loading";
import ButtonRounded from "ui/ButtonRounded";
import Text from "ui/Text";
import TextFieldLight from "ui/TextFieldLight";
import { isEmail, isPassword, isRequired, validator } from "utils";
import { COLORS } from "utils/theme";

const useEndAdornmentStyles = makeStyles({
  root: {
    cursor: "pointer",
    fill: COLORS.BLUE_3,
  },
});

export default function SignupForm({ createAccount }: any) {
  const endAdornmentClasses = useEndAdornmentStyles();

  const passwordInfoIconRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  useEffect(() => {
    if (emailError) {
      setEmailError("");
    }
  }, [email]);

  const signInError = useSelector(errorMessageSelector("signUp"));
  useEffect(() => {
    setEmailError(signInError);
  }, [signInError]);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  useEffect(() => {
    if (passwordError) {
      setPasswordError("");
    }
  }, [password]);

  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  useEffect(() => {
    if (passwordConfirmError) {
      setPasswordError("");
      setPasswordConfirmError("");
    }
  }, [password, passwordConfirm]);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggleIsPasswordVisible = () =>
    setIsPasswordVisible(!isPasswordVisible);
  const PasswordFieldsEndAdornment = isPasswordVisible
    ? VisibilityOutlined
    : VisibilityOffOutlined;

  const isEmailValid = useCallback(() => {
    const emailError = validator([isRequired, isEmail])(email);
    if (emailError) {
      setEmailError(emailError);
    }
  }, [email]);

  const isFormValid = email && !emailError && password && passwordConfirm;

  async function onSignUp() {
    const passwordError = validator([isPassword])(password);
    const passwordConfirmError = validator([
      (passwordConfirm) =>
        password === passwordConfirm ? "" : "Password does not match",
    ])(passwordConfirm);

    if (passwordError) {
      setPasswordError(passwordError);
      setAnchorEl(passwordInfoIconRef.current);
    } else if (passwordConfirmError) {
      setPasswordError(passwordConfirmError);
      setPasswordConfirmError(passwordConfirmError);
    } else {
      createAccount({ email, password });
    }
  }

  const isLoading = useSelector(loadingSelector("signUp"));

  return (
    <Box
      sx={{
        bgcolor: COLORS.PRIMARY,
        width: 433,
      }}
    >
      <Box
        pt={4}
        sx={{
          pb: "24px",
          px: "48px",
        }}
      >
        <Box mb={2}>
          <Text component="span" fontWeight="bold" variant="h6">
            Register
          </Text>
        </Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box
            sx={{
              mb: "24px",
            }}
          >
            <TextFieldLight
              error={emailError}
              label="Email address"
              onBlur={isEmailValid}
              onChange={setEmail}
              value={email}
            />
          </Box>
          <Box
            sx={{
              mb: "24px",
              position: "relative",
            }}
          >
            <TextFieldLight
              endAdornment={
                <PasswordFieldsEndAdornment
                  classes={endAdornmentClasses}
                  onClick={toggleIsPasswordVisible}
                />
              }
              error={passwordError}
              label="Password"
              onChange={setPassword}
              {...(!isPasswordVisible && { type: "password" })}
              value={password}
            />
            <Box
              sx={{
                position: "absolute",
                right: "-36px",
                top: "16px",
              }}
            >
              <InfoOutlined
                onClick={handlePopoverOpen}
                ref={passwordInfoIconRef}
                style={{ cursor: "pointer" }}
              />
              <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                onClose={handlePopoverClose}
              >
                <Box
                  sx={{
                    p: "16px",
                    width: 250,
                  }}
                >
                  <Typography color="textSecondary" variant="body2">
                    Your password must include at least 6 characters and
                    contains the following:
                  </Typography>
                  <br />
                  <Typography color="textSecondary" variant="body2">
                    - At least one uppercase character
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    - At least one lowercase character
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    - At least one number
                  </Typography>
                </Box>
              </Popover>
            </Box>
          </Box>
          <Box mb={4}>
            <TextFieldLight
              endAdornment={
                <PasswordFieldsEndAdornment
                  classes={endAdornmentClasses}
                  onClick={toggleIsPasswordVisible}
                />
              }
              error={passwordConfirmError}
              label="Password confirmation"
              onChange={setPasswordConfirm}
              {...(!isPasswordVisible && { type: "password" })}
              value={passwordConfirm}
            />
          </Box>
          <Box px={2}>
            <ButtonRounded
              color={COLORS.BUTTON_PRIMARY}
              disabled={!isFormValid}
              isLoading={isLoading}
              label="Sign up"
              labelBold
              onClick={onSignUp}
              type="submit"
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
}
