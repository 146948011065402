import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";

import PasswordForgot from "components/PasswordForgot";
import { signIn } from "store/account";
import { errorSelector } from "store/error";
import { loadingSelector } from "store/loading";
import ButtonRounded from "ui/ButtonRounded";
import Text from "ui/Text";
import TextFieldLight from "ui/TextFieldLight";
import TextLink from "ui/TextLink";
import { COLORS } from "utils/theme";
import { isEmail, isRequired, validator } from "utils";

const useEndAdornmentStyles = makeStyles({
  root: {
    cursor: "pointer",
    fill: COLORS.BLUE_3,
  },
});

const LoginForm = () => {
  const dispatch = useDispatch();
  const endAdornmentClasses = useEndAdornmentStyles();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  useEffect(() => {
    if (emailError) {
      setEmailError("");
    }
    if (passwordError) {
      setPasswordError("");
    }
  }, [email]);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  useEffect(() => {
    if (passwordError) {
      setEmailError("");
      setPasswordError("");
    }
  }, [password]);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggleIsPasswordVisible = () =>
    setIsPasswordVisible(!isPasswordVisible);
  const PasswordFieldsEndAdornment = isPasswordVisible
    ? VisibilityOutlined
    : VisibilityOffOutlined;

  const isEmailValid = useCallback(() => {
    const emailError = validator([isRequired, isEmail])(email);
    if (emailError) {
      setEmailError(emailError);
    }
  }, [email]);

  const isFormValid = email && !emailError && password;

  const loginError = useSelector(errorSelector("signIn"));
  useEffect(() => {
    setEmailError(loginError?.message ?? "");
    setPasswordError(loginError?.message ?? "");
  }, [loginError]);

  const onSignIn = () => {
    dispatch(signIn({ email, password }));
  };

  const isLoading = useSelector(loadingSelector("signIn"));

  return (
    <Box
      sx={{
        bgcolor: COLORS.PRIMARY,
        width: 433,
      }}
    >
      <Box
        sx={{
          pt: "48px",
          pb: "24px",
          px: "48px",
        }}
      >
        <Box mb={2}>
          <Text component="span" fontWeight="bold" variant="h6">
            Log in
          </Text>
        </Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box
            sx={{
              mb: "32px",
            }}
          >
            <TextFieldLight
              error={emailError}
              label="Email address"
              onBlur={isEmailValid}
              onChange={setEmail}
              value={email}
            />
          </Box>
          <Box
            sx={{
              mb: "8px",
            }}
          >
            <TextFieldLight
              endAdornment={
                <PasswordFieldsEndAdornment
                  classes={endAdornmentClasses}
                  onClick={toggleIsPasswordVisible}
                />
              }
              error={passwordError}
              label="Password"
              onChange={setPassword}
              {...(!isPasswordVisible && { type: "password" })}
              value={password}
            />
          </Box>
          <Box
            mb={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <PasswordForgot />
          </Box>
          <Box mb={4} px={2}>
            <ButtonRounded
              color={COLORS.BUTTON_PRIMARY}
              disabled={!isFormValid}
              isLoading={isLoading}
              label="Sign in"
              labelBold
              onClick={onSignIn}
              type="submit"
            />
            {loginError?.code === "ERR_ACCOUNT_012" && (
              <Box sx={{ mt: "16px" }}>
                <Text fontSize="14px">
                  Having connection problems? &nbsp;
                  <TextLink>
                    <a href="mailto: contact@firstlandofpoker.com">
                      Contact us{" "}
                    </a>
                  </TextLink>
                </Text>
              </Box>
            )}
          </Box>
        </form>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography color="textPrimary">
            Don't have an account yet?
          </Typography>
          <Link component={RouterLink} to="/signup" underline="none">
            <TextLink color={COLORS.ACCENT_1}>Create one</TextLink>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
