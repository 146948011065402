import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

import { COLORS } from "utils/theme";

export default function FullScreenLoader() {
  return (
    <Box
      bgcolor={COLORS.SECONDARY}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <CircularProgress thickness={2} style={{ color: COLORS.WHITE }} />
    </Box>
  );
}
