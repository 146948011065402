import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

import API from "api";
import HandStrategy from "components/HandStrategy";
import StrategyOverviewCard from "components/StrategyOverviewCard";
import StrategyOverviewFrequencyBar from "components/StrategyOverviewFrequencyBar";
import ThemeSelector from "components/ThemeSelector";
import ButtonRounded from "ui/ButtonRounded";
import Text from "ui/Text";
import { Hand, StrategyOverview } from "utils/models";
import {
  COLORS,
  ColorVariants,
  COLOR_VARIANTS,
  RAISE_ACTIONS_REGEX,
} from "utils/theme";

import forbiddenPng from "assets/icons/forbidden.png";
import refreshPng from "assets/icons/refresh.png";

type Props = {
  branchId?: number;
  resetSimulation: () => void;
  solutionId?: number;
};

const useSwitchStyles = makeStyles({
  root: {
    height: "2vw",
    padding: "0.62vw",
    width: "3vw",
  },
  switchBase: {
    padding: "0.47vw",
    "& + .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: COLORS.ACCENT_3,
    },
    "&.Mui-checked": {
      color: COLORS.BLUE_5,
      transform: "translateX(1vw)",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: COLORS.ACCENT_1,
      },
    },
  },
  thumb: {
    borderRadius: "1vw",
    height: "1vw",
    width: "1vw",
  },
  track: {
    borderRadius: "0.36vw",
  },
});

const ResolutionStrategies: React.FC<Props> = ({
  branchId,
  resetSimulation,
  solutionId,
}) => {
  const switchClasses = useSwitchStyles();

  const colorVariantStorage = localStorage.getItem(
    "colorVariant"
  ) as ColorVariants;

  const [
    selectedColorVariant,
    setSelectedColorVariant,
  ] = useState<ColorVariants>(colorVariantStorage || COLOR_VARIANTS[0].value);
  useEffect(() => {
    localStorage.setItem("colorVariant", selectedColorVariant);
  }, [selectedColorVariant]);

  const [isFinished, setIsFinished] = useState(false);
  const [strategiesOverview, setStrategiesOverview] = useState<
    StrategyOverview[]
  >([]);
  const [hands, setHands] = useState<Hand[]>([]);

  const [displayWeights, setDisplayWeights] = useState(true);

  useEffect(() => {
    const fetchStrategies = async () => {
      // We want to fetch both strategies and hands at the same time, so the right part of the table will be updated at the same time.
      await Promise.all([
        API.getStrategiesOverview(solutionId!, branchId).catch((error) => {
          if (error.message === "ERR_SOLUTION_FINISHED") {
            setIsFinished(true);
          }
          return error;
        }),
        API.getStrategiesHands(solutionId!, branchId),
      ]).then((values) => {
        setStrategiesOverview(values[0]);
        setHands(values[1]);
      });

      setIsFinished(false);
    };
    if (solutionId) {
      fetchStrategies();
    }
  }, [branchId, solutionId]);

  const raiseStrategies = strategiesOverview
    .filter(({ action }) => action.search(RAISE_ACTIONS_REGEX) !== -1)
    .map(({ action }) => action);

  const hasNotNullStrategy =
    !strategiesOverview.length ||
    !!strategiesOverview.find(({ rangeFrequency }) => rangeFrequency > 0);

  return (
    <Box
      sx={{
        bgcolor: COLORS.PRIMARY,
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "min-content",
        minHeight: "100%",
      }}
    >
      {isFinished ? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ mb: "1.67vw" }}>
            <img alt="" src={refreshPng} style={{ width: "5.4vw" }} />
          </Box>
          <Box sx={{ mb: "1.67vw", textAlign: "center" }}>
            <Text color={COLORS.WHITE} fontSize="0.94vw">
              Looks like you have reached the
              <br /> end of the simulation!
            </Text>
          </Box>
          <ButtonRounded
            borderColor={COLORS.ACCENT_1}
            fullWidth={false}
            label="Start a new one"
            labelColor={COLORS.ACCENT_1}
            labelUppercased={false}
            onClick={resetSimulation}
          />
        </Box>
      ) : hasNotNullStrategy ? (
        <>
          <Box
            sx={{
              display: "flex",
              pt: "0.83vw",
            }}
          >
            <Box sx={{ pl: "16px" }}>
              <ThemeSelector
                colorVariant={selectedColorVariant}
                onSelectColorVariant={setSelectedColorVariant}
              />
            </Box>
            <Box
              sx={{
                color: COLORS.WHITE,
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                marginRight: "32px",
              }}
            >
              <Box
                className="weight-switcher-tour"
                sx={{ alignItems: "center", display: "flex" }}
              >
                <Text>Strategy</Text>
                <Switch
                  checked={displayWeights}
                  classes={switchClasses}
                  onChange={({ target: { checked } }) =>
                    setDisplayWeights(checked)
                  }
                />
                <Text>Range</Text>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              mb: "1.7vw",
              px: "4.6vw",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                mb: "2vw",
              }}
            >
              {strategiesOverview.map((strategy) => (
                <StrategyOverviewCard
                  colorVariant={selectedColorVariant}
                  key={`overview-card-${strategy.rangeId}`}
                  raiseStrategies={raiseStrategies}
                  strategy={strategy}
                />
              ))}
            </Box>
            <Box sx={{ mb: "2vw" }}>
              <StrategyOverviewFrequencyBar
                colorVariant={selectedColorVariant}
                raiseStrategies={raiseStrategies}
                strategiesOverview={strategiesOverview}
              />
            </Box>
            <Box>
              {!!hands.length && (
                <HandStrategy
                  colorVariant={selectedColorVariant}
                  displayWeights={displayWeights}
                  hands={hands}
                  raiseStrategies={raiseStrategies}
                  strategiesOverview={strategiesOverview}
                />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ mb: "1.67vw" }}>
            <img alt="" src={forbiddenPng} style={{ width: "5.4vw" }} />
          </Box>
          <Box sx={{ mb: "0.83vw", textAlign: "center" }}>
            <Text color={COLORS.WHITE} fontSize="0.95vw" fontWeight="bold">
              Wow, you are not supposed to be there!
            </Text>
          </Box>
          <Box sx={{ mb: "1.67vw", textAlign: "center" }}>
            <Text color={COLORS.WHITE} fontSize="0.95vw">
              There is no range to display here because the player
              <br /> would never take this line with any hand.
            </Text>
          </Box>
          <ButtonRounded
            borderColor={COLORS.ACCENT_1}
            fullWidth={false}
            label="Start a new simulation"
            labelColor={COLORS.ACCENT_1}
            labelUppercased={false}
            onClick={resetSimulation}
          />
        </Box>
      )}
    </Box>
  );
};

export default ResolutionStrategies;
