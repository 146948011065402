import React from "react";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

import Text from "ui/Text";
import { COLORS } from "utils/theme";

type Props = {
  gameFormats: string[];
  onSelectGameFormat: (gameFormat: string) => void;
  selectedGameFormat: string;
};

const useSelectStyles = makeStyles({
  iconFilled: {
    height: "1.25vw",
    right: "0.36vw",
    top: "calc(50% - 0.62vw)",
    width: "1.25vw",
  },
  root: {
    alignItems: "center",
    borderRadius: "1vw",
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY,
    display: "flex",
    height: "2.1vw",
    lineHeight: "0.94vw",
    padding: "0px",
    paddingLeft: "0.83vw",
    width: "10.4vw",
  },
  select: {
    "&:focus": {
      backgroundColor: COLORS.WHITE,
      borderRadius: "1vw",
    },
  },
});

const useMenuStyles = makeStyles({
  paper: {
    borderRadius: "1vw",
  },
  list: {
    color: COLORS.PRIMARY,
  },
});

const GameFormatSelector: React.FC<Props> = ({
  gameFormats,
  onSelectGameFormat,
  selectedGameFormat,
}) => {
  const selectClasses = useSelectStyles();
  const menuClasses = useMenuStyles();

  return (
    <Box sx={{ pl: "0.83vw" }}>
      <Box sx={{ mb: "0.4vw", pl: "0.2vw" }}>
        <Text color={COLORS.WHITE} fontSize="0.85vw" fontWeight={700}>
          2 - Select game format
        </Text>
      </Box>
      <Select
        classes={selectClasses}
        className="game-format-tour"
        disableUnderline
        MenuProps={{
          classes: menuClasses,
          elevation: 0,
          MenuListProps: { disablePadding: true },
        }}
        onChange={({ target: { value } }) => onSelectGameFormat(value)}
        variant="filled"
        value={selectedGameFormat}
      >
        {gameFormats.map((gameFormat) => {
          return (
            <MenuItem
              key={gameFormat}
              // sx={{ ...(disabled && { pointerEvents: "none" }) }}
              value={gameFormat}
            >
              <Box
                sx={{
                  fontSize: "0.85vw",
                  // ...(disabled && { opacity: 0.4, pointerEvents: "none" }),
                  "&:focus": { outline: "none" },
                }}
              >
                {gameFormat}
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default GameFormatSelector;
