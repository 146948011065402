import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Cookies from "js-cookie";

import { COLORS } from "utils/theme";
import Text from "ui/Text";
import ButtonRounded from "ui/ButtonRounded";

const CookieBanner = () => {
  const [showConsent, setShowConsent] = useState(true);

  const acceptCookie = () => {
    Cookies.set("gdpr-analytics-enabled", "true");
    setShowConsent(false);
  };

  const declineCookie = () => {
    Cookies.set("gdpr-analytics-enabled", "false");
    setShowConsent(false);
  };

  return Cookies.get("gdpr-analytics-enabled") === undefined && showConsent ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        position: "absolute",
        bottom: "0",
        boxSizing: "border-box",
        width: "100%",
        px: "56px",
        py: "20px",
        backgroundColor: COLORS.PRIMARY_OPACITY_50,
        alignItems: "center",
      }}
    >
      <Text fontWeight="bold" color="#FFFFFF">
        This website uses cookies to enhance the user experience.
      </Text>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ mr: "24px" }}>
          <ButtonRounded
            color={COLORS.BLUE_3}
            buttonHeight="30px"
            id="cookie-decline"
            label="Decline"
            labelBold
            labelUppercased={false}
            fullWidth={false}
            onClick={declineCookie}
          />
        </Box>
        <ButtonRounded
          color={COLORS.ACCENT_1}
          buttonHeight="30px"
          id="cookie-consent"
          label="Accept"
          labelBold
          labelUppercased={false}
          fullWidth={false}
          onClick={acceptCookie}
        />
      </Box>
    </Box>
  ) : null;
};

export default CookieBanner;
