import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import Text from "ui/Text";
import { COLORS } from "utils/theme";

type Props = {
  onSelectStackSize: (size: number) => void;
  selectedStackSize: number;
  stacks: number[];
};

const StackSizeSelector: React.FC<Props> = ({
  onSelectStackSize,
  selectedStackSize,
  stacks,
}) => {
  const [stackSize, setStackSize] = useState<number>();

  useEffect(() => {
    setStackSize(stacks.findIndex((stack) => stack === selectedStackSize));
  }, [selectedStackSize]);

  return (
    <Box sx={{ pl: "0.83vw" }}>
      <Box sx={{ mb: "0.41vw", pl: "0.21vw" }}>
        <Text color={COLORS.WHITE} fontSize="0.85vw" fontWeight={700}>
          3 - Choose stack size (in bb)
        </Text>
      </Box>
      <Slider
        className="stack-value-tour"
        dotStyle={{
          border: "none",
          height: 0,
          width: 0,
        }}
        handleStyle={{
          border: "none",
          height: "22px",
          width: "22px",
        }}
        marks={stacks.map((stack, index) => ({
          label: stack,
          style: {
            color: index === stackSize ? COLORS.WHITE : COLORS.BLUE_6,
            fontSize: "16px",
            top: "6px",
          },
          value: index,
        }))}
        max={stacks.length - 1}
        min={0}
        onChange={(value: number) => {
          setStackSize(value);
        }}
        onAfterChange={() => {
          onSelectStackSize(stacks[stackSize!]);
        }}
        railStyle={{
          backgroundColor: COLORS.PRIMARY,
          height: "12px",
        }}
        step={null}
        trackStyle={{
          backgroundColor: COLORS.ACCENT_1,
          height: "12px",
        }}
        value={stackSize}
      />
    </Box>
  );
};

export default StackSizeSelector;
