import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { groupBy, map, uniq } from "lodash/fp";
import Box from "@material-ui/core/Box";

import API from "api";
import ActionsBar from "components/ActionsBar";
import BranchTraveler from "components/BranchTraveler";
import FullScreenLoader from "components/FullScreenLoader";
import GameFormatSelector from "components/GameFormatSelector";
import GameTypeSelector from "components/GameTypeSelector";
import PlayerPositionCard from "components/PlayerPositionCard";
import ResolutionStrategies from "components/ResolutionStrategies";
import StackSizeSelector from "components/StackSizeSelector";
import GTOTour from "pages/GTO/GTOTour";
import { accountSelector } from "store/account";
import Text from "ui/Text";
import { Action, GameState, Solution } from "utils/models";
import PLAYERS_POSITION, { DEALER_POSITION } from "utils/players_position";
import { COLORS, getActionLabel } from "utils/theme";

import stackSmPng from "assets/icons/stack_sm.png";
import stackMdPng from "assets/icons/stack_md.png";
import stackLgPng from "assets/icons/stack_lg.png";
import dealerButtonPng from "assets/images/dealer_button.png";
import flopTablePlaceholder from "assets/images/flop_table_placeholder.png";
import pokerTableSvg from "assets/images/poker_table.svg";
import mixpanel from "mixpanel-browser";
import { EVENTS } from "constants/tracking";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

const getStackIcon = (stack: number) =>
  stack < 1 ? stackSmPng : stack < 2 ? stackMdPng : stackLgPng;

type Props = {
  closeGTOTour: () => void;
  isGTOTourOpened: boolean;
};

const GTO: React.FC<Props> = ({ closeGTOTour, isGTOTourOpened }) => {
  const { access_token } = useSelector(accountSelector) ;
  const [solutions, setSolutions] = useState<{ [key: string]: Solution[] }>({});
  const [selectedGameType, setSelectedGameType] = useState("");
  const [selectedGameFormat, setSelectedGameFormat] = useState("");
  const [selectedStackSize, setSelectedStackSize] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [branchIds, setBranchIds] = useState<
    { branchId: number; playerId: number }[]
  >([]);
  const [currentBranchIdIndex, setCurrentBranchIdIndex] = useState<number>(-2);

  const [actions, setActions] = useState<Action[]>([]);
  const [gameState, setGameState] = useState<GameState>();

  useEffect(() => {
    const fetchSolutions = async () => {
      const solutions = groupBy<Solution>(
        ({ gameType, gameVariant }) => `${gameType}-${gameVariant}`
      )(await API.getSolutions());

      const defaultGameType = Object.keys(solutions)
        .filter((gameType) => !!solutions[gameType].find(({ id }) => !!id))
        .sort()[0];
      setSelectedGameType(defaultGameType);

      const defaultGameFormat = map("tableSize")(
        solutions[defaultGameType]
      ).sort()[0];
      setSelectedGameFormat(defaultGameFormat);

      const defaultStackSize = map("bbStack")(
        solutions[defaultGameType].filter(({ id }) => !!id)
      ).sort((a, b) => a - b)[0];
      setSelectedStackSize(defaultStackSize);

      setSolutions(solutions);
    };
    if (access_token && !selectedGameType) {
      fetchSolutions();
    }
  }, [access_token]);

  /**
   * When updating the current branch id index, we need to refresh the whole page and all datas.
   */
  useEffect(() => {
    const updateActionBoard = async () => {
      const solutionId = solutions[selectedGameType].find(
        ({ bbStack, tableSize }) =>
          tableSize === selectedGameFormat && bbStack === selectedStackSize
      )!.id;
      const currentBranchId =
        currentBranchIdIndex === -1
          ? // For some cases, we need to initialize the board into a particular node (for example, BvB),
            //so we need to consider the initial branch that coming from the backend.
            selectedSolution!.initialBranch
          : branchIds[currentBranchIdIndex]?.branchId;
      const [actions, gameState] = await Promise.all([
        API.getSolutionActions(solutionId, currentBranchId),
        API.getSolutionGameState(solutionId, currentBranchId),
      ]);
      setActions(actions);
      setGameState(gameState);
    };
    // Launching the board action loading when everything is selected.
    if (
      selectedGameType &&
      selectedGameFormat &&
      selectedStackSize &&
      currentBranchIdIndex !== -2
    ) {
      updateActionBoard();
    }
  }, [currentBranchIdIndex]);

  const onSelectGameType = (gameType: string) => {
    const defaultGameFormat = map("tableSize")(solutions[gameType]).sort()[0];
    const defaultStackSize = map("bbStack")(
      solutions[gameType].filter(
        ({ tableSize }) => tableSize === defaultGameFormat
      )
    ).sort()[0];
    setSelectedGameType(gameType);
    setSelectedGameFormat(defaultGameFormat);
    setSelectedStackSize(defaultStackSize);
    setBranchIds([]);
    setCurrentBranchIdIndex(-2);
    setActions([]);
    setGameState(undefined);
  };

  const onSelectGameFormat = (gameFormat: string) => {
    const defaultStackSize = map("bbStack")(
      solutions[selectedGameType].filter(
        ({ tableSize }) => tableSize === gameFormat
      )
    ).sort()[0];
    setSelectedGameFormat(gameFormat);
    setSelectedStackSize(defaultStackSize);
    setActions([]);
    setGameState(undefined);
    setBranchIds([]);
    setCurrentBranchIdIndex(-2);
  };

  const onSelectStackSize = (stackSize: number) => {
    setBranchIds([]);
    setCurrentBranchIdIndex(-2);
    setSelectedStackSize(stackSize);
  };

  const selectedSolution = solutions[selectedGameType]?.find(
    ({ bbStack, tableSize }) =>
      tableSize === selectedGameFormat && bbStack === selectedStackSize
  );

  const onSelectAction = async (branchId: number) => {
    branchIds.splice(currentBranchIdIndex + 1, branchIds.length, {
      branchId,
      playerId: actions[0].actionPlayerId,
    });
    setBranchIds(branchIds);
    setCurrentBranchIdIndex(branchIds.length - 1);
  };

  const cancelPlayerAction = (playerIndex: number) => {
    const playerLastActionIndex = branchIds
      .map(({ playerId }) => playerId)
      .lastIndexOf(playerIndex);
    branchIds.splice(playerLastActionIndex);
    setBranchIds(branchIds);
    setCurrentBranchIdIndex(playerLastActionIndex - 1);
  };

  const foldTo = async (playerIndex: number) => {
    console.log("fold to ", playerIndex);
    if (
      actions[0]?.actionPlayerId !== undefined &&
      playerIndex !== actions[0].actionPlayerId
    ) {
      const selectedSolution = solutions[selectedGameType].find(
        ({ bbStack, tableSize }) =>
          tableSize === selectedGameFormat && bbStack === selectedStackSize
      )!;
      const currentBranchId =
        currentBranchIdIndex === -1
          ? // For some cases, we need to initialize the board into a particular node (for example, BvB),
            //so we need to consider the initial branch that coming from the backend.
            // Idk why we should do it here, but I won't change it now.
            selectedSolution!.initialBranch
          : branchIds[currentBranchIdIndex].branchId;

      const playersActionsIndex = Object.keys(gameState!.playersActions);
      const foldedPlayersActionsIndex =
        actions[0].actionPlayerId < playerIndex
          ? playersActionsIndex.slice(actions[0].actionPlayerId, playerIndex)
          : playersActionsIndex
              .slice(actions[0].actionPlayerId)
              .concat(playersActionsIndex.slice(0, playerIndex));

      const foldCount = foldedPlayersActionsIndex.filter(
        (playerActionsIndex) =>
          !gameState!
            .playersActions![
              +playerActionsIndex
            ].map(({ action, actionValueInBb }) =>
              getActionLabel(action, actionValueInBb)
            )
            .includes("FOLD")
      ).length;

      const newBranchIds = await API.getFoldToAction(
        selectedSolution.id,
        foldCount,
        currentBranchId
      );
      if (currentBranchIdIndex === -1) {
        setBranchIds(
          newBranchIds.map((branchId, index) => ({
            branchId,
            playerId:
              (actions[0].actionPlayerId + index) %
              selectedSolution.playerNumber,
          }))
        );
        setCurrentBranchIdIndex(newBranchIds.length - 1);
      } else {
        branchIds.splice(
          currentBranchIdIndex + 1,
          branchIds.length,
          ...newBranchIds.slice(1).map((branchId, index) => ({
            branchId,
            playerId:
              (actions[0].actionPlayerId + index) %
              selectedSolution.playerNumber,
          }))
        );
        setBranchIds(branchIds);
        setCurrentBranchIdIndex(branchIds.length - 1);
      }
    }
  };

  useEffect(() => {
    const comesFromSubscriptionPage = new URLSearchParams(location.search).get(
      "justSubscribed"
    );

    if (comesFromSubscriptionPage) {
      mixpanel.track(EVENTS.CONVERTED_TO_PREMIUM);

      enqueueSnackbar(
        "Congratulation, you have successfully upgraded your account!",
        {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          variant: "success",
        }
      );
    }
  }, []);

  const resetSimulation = async () => {
    setBranchIds([]);
    setCurrentBranchIdIndex(-2);
  };

  useEffect(() => {
    // This is a temporary state where we want to put currentBranchIdIndex
    // in order to say that's not the current "empty state", this is only
    // a way to say it's a "null" state.
    const fetchSolutions = async () => {
      setCurrentBranchIdIndex(-1);
    };
    if (
      solutions &&
      selectedGameType &&
      selectedStackSize &&
      !!Object.keys(solutions).length &&
      currentBranchIdIndex === -2
    ) {
      fetchSolutions();
    }
  }, [
    selectedGameType,
    selectedGameFormat,
    selectedStackSize,
    solutions,
    currentBranchIdIndex,
  ]);

  if (!Object.keys(solutions).length) return <FullScreenLoader />;

  return (
    <>
      <GTOTour closeGTOTour={closeGTOTour} isGTOTourOpened={isGTOTourOpened} />
      <Box
        sx={{
          bgcolor: COLORS.SECONDARY,
          display: "flex",
          flex: 1,
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            bgcolor: COLORS.SECONDARY,
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "min-content",
            minHeight: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              px: "2vw",
              py: "1.25vw",
            }}
          >
            <Box sx={{ display: "flex", mb: "0.83vw" }}>
              <GameTypeSelector
                onSelectGameType={onSelectGameType}
                selectedGameType={selectedGameType}
                solutions={solutions}
              />
              <GameFormatSelector
                gameFormats={uniq(
                  map("tableSize")(
                    solutions[selectedGameType].filter(({ id }) => !!id)
                  )
                ).sort((a, b) => a - b)}
                onSelectGameFormat={onSelectGameFormat}
                selectedGameFormat={selectedGameFormat}
              />
            </Box>
            <Box sx={{ mb: "1.83vw" }}>
              <StackSizeSelector
                onSelectStackSize={onSelectStackSize}
                selectedStackSize={selectedStackSize}
                stacks={map("bbStack")(
                  solutions[selectedGameType]
                    .filter(({ tableSize }) => tableSize === selectedGameFormat)
                    .filter(({ id }) => !!id)
                ).sort((a, b) => a - b)}
              />
            </Box>
            <Box sx={{ position: "relative" }}>
              <img
                alt="poker table"
                src={pokerTableSvg}
                style={{ width: "100%" }}
              />
              <img
                alt="poker table placeholder"
                src={flopTablePlaceholder}
                style={{
                  left: "39.5%",
                  opacity: "0.5",
                  position: "absolute",
                  top: "37%",
                  width: "10vw",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  top: "47%",
                  width: "100%",
                }}
              >
                {gameState && (
                  <Box
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      borderRadius: "16px",
                      p: "4px 12px",
                      mr: "4px",
                    }}
                  >
                    <Text color={COLORS.WHITE} fontSize="0.85vw">
                      Pot:{" "}
                      {parseFloat(
                        (gameState!.betSize + gameState!.potSize).toFixed(10)
                      )}
                    </Text>
                  </Box>
                )}
                {!!selectedSolution?.ante && (
                  <Box
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      borderRadius: "16px",
                      p: "4px 12px",
                      mr: "4px",
                    }}
                  >
                    <Text color={COLORS.WHITE} fontSize="0.85vw">
                      Ante: {selectedSolution?.ante}
                    </Text>
                  </Box>
                )}
                <Box
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "16px",
                    p: "4px 12px",
                  }}
                >
                  <Text color={COLORS.WHITE} fontSize="0.85vw">
                    Pot odds: {gameState?.potOdds} (
                    {gameState?.potOddsPercentage}%)
                  </Text>
                </Box>
              </Box>
              {selectedSolution &&
                gameState &&
                PLAYERS_POSITION[selectedSolution.playerNumber].map(
                  ({ bet, label, left, sublabel, top }, index) => (
                    <Box
                      {...(index === 0 && { className: "player-card-tour" })}
                      key={`${label}-${index}`}
                      sx={{ left, position: "absolute", top }}
                    >
                      {index === 0 && (
                        <img
                          alt="dealer button"
                          src={dealerButtonPng}
                          style={{
                            height: "2vw",
                            left:
                              DEALER_POSITION[selectedSolution.playerNumber]
                                .left,
                            position: "absolute",
                            top:
                              DEALER_POSITION[selectedSolution.playerNumber]
                                .top,
                            width: "2vw",
                          }}
                        />
                      )}
                      {!!gameState.playersBet[index] && (
                        <Box
                          sx={{
                            display: "flex",
                            left: bet?.left,
                            position: "absolute",
                            top: bet?.top,
                          }}
                        >
                          <img
                            alt=""
                            src={getStackIcon(gameState.playersBet[index])}
                            style={{ height: "1.25vw", marginRight: "0.2vw" }}
                          />
                          <Text color={COLORS.WHITE} fontSize="0.85vw">
                            {gameState.playersBet[index]}
                          </Text>
                        </Box>
                      )}
                      <Box
                        onClick={() => foldTo(index)}
                        sx={{ cursor: "pointer" }}
                      >
                        <PlayerPositionCard
                          availableActions={actions}
                          isPlaying={actions[0]?.actionPlayerId === index}
                          label={label}
                          onCancelAction={() => cancelPlayerAction(index)}
                          onSelectAction={onSelectAction}
                          pastActions={gameState.playersActions[index]}
                          stack={gameState.playersStacks[index]}
                          sublabel={sublabel}
                        />
                      </Box>
                    </Box>
                  )
                )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "-1.25vw",
                mb: "1.25vw",
              }}
            >
              <ActionsBar
                actions={actions.slice().reverse()}
                onSelectAction={onSelectAction}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <BranchTraveler
                canRedo={currentBranchIdIndex !== branchIds.length - 1}
                canReset={currentBranchIdIndex !== -1}
                canUndo={currentBranchIdIndex !== -1}
                redoAction={() =>
                  setCurrentBranchIdIndex(currentBranchIdIndex + 1)
                }
                resetSimulation={resetSimulation}
                undoAction={() =>
                  setCurrentBranchIdIndex(currentBranchIdIndex - 1)
                }
              />
            </Box>
          </Box>
        </Box>
        <ResolutionStrategies
          branchId={
            currentBranchIdIndex !== -2 &&
            branchIds[currentBranchIdIndex]?.branchId
              ? branchIds[currentBranchIdIndex]?.branchId
              : selectedSolution?.initialBranch
          }
          resetSimulation={resetSimulation}
          solutionId={selectedSolution?.id}
        />
      </Box>
    </>
  );
};

export default GTO;
