import React from "react";
import { Box } from "@material-ui/core";
import { COLORS } from "../utils/theme";
import "./Radio.css";
import classNames from "classnames";

export interface RadioProps {
  isChecked?: boolean;
  onSelect: any;
}

export default function Radio({ isChecked, onSelect }: RadioProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      width="30px"
      height="30px"
      className={classNames("flop-radio-input", {
        "flop-radio-input-checked": isChecked,
      })}
      onClick={onSelect}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="26px"
        height="26px"
        borderRadius="50%"
        bgcolor={COLORS.PRIMARY}
        className="flop-radio-outer-container"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="18px"
          height="18px"
          borderRadius="50%"
          bgcolor={COLORS.PRIMARY}
          className="flop-radio-inner-container"
        />
      </Box>
    </Box>
  );
}
