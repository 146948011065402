import React, { useEffect, useState } from "react";
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import { StylesProvider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import TagManager from "react-gtm-module";
import { SnackbarProvider } from "notistack";
import FullScreenLoader from "components/FullScreenLoader";
import CookieBanner from "components/CookieBanner";
import Navbar from "components/Navbar";
import ContactUs from "pages/ContactUs";
import GTO from "pages/GTO";
import Login from "pages/Login";
import Settings from "pages/Settings";
import Signup from "pages/Signup";
import SignupCompletion from "./pages/SignupCompletion/SignupCompletion";
import TermsAndConditions from "pages/TermsAndConditions";
import store from "store";
import { accountSelector, fetchProfile, refreshToken } from "store/account";
import {
  fetchActiveSubscription,
  getActiveSubscription,
} from "store/subscription";
import { COLORS } from "utils/theme";
import {
  CONTACT_US,
  LOGIN,
  ROOT,
  SETTINGS,
  SIGNUP,
  TERMS_AND_CONDITIONS,
} from "./constants/routes";
import { MIXPANEL_ID } from "./constants/tracking";
import history from "./history";
import { PlanType } from "./types";
import Text from "./ui/Text";
import ButtonRounded from "./ui/ButtonRounded";
import API from "./api";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    text: {
      primary: COLORS.WHITE,
      secondary: COLORS.BLACK,
    },
  },
  typography: {
    fontFamily: ["Lato", "Helvetica", "sans-serif"].join(","),
  },
});

// Configure Google Tag Manager
if (process.env.NODE_ENV !== "development") {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string,
    auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH as string,
    preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV as string,
  };

  TagManager.initialize(tagManagerArgs);
}

mixpanel.init(MIXPANEL_ID);

const useNotificationStyles = makeStyles({
  anchorOriginTopCenter: {
    top: 50,
  },
});

function LoggedShell() {
  const dispatch = useDispatch();
  const [shouldPay, setShouldPay] = useState(false);
  const [isDowngrading, setIfDowngrading] = useState(false);
  const [isGTOTourOpened, setIsGTOTourOpened] = useState(false);
  const notificationStyles = useNotificationStyles();

  const { email, emailVerified, originalChosenPackage, userId } = useSelector(
    accountSelector
  );
  const activeSubscription = useSelector(getActiveSubscription);

  async function downgradeToFree() {
    setIfDowngrading(true);
    await API.downgradeToFree(userId);
    await dispatch(fetchProfile(userId));
    setShouldPay(false);
    setIfDowngrading(false);
  }

  useEffect(() => {
    dispatch(fetchProfile(userId));
  }, [userId]);

  useEffect(() => {
    if (email && emailVerified) {
      dispatch(fetchActiveSubscription(userId));
    }
  }, [userId, emailVerified, email]);

  useEffect(() => {
    if (originalChosenPackage === PlanType.PREMIUM) {
      setShouldPay(true);
    }
  }, [originalChosenPackage]);

  if (!email) {
    return <FullScreenLoader />;
  }

  if (!emailVerified) {
    return <SignupCompletion />;
  }

  return (
    <SnackbarProvider classes={notificationStyles}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Navbar openGTOTour={() => setIsGTOTourOpened(true)} />
        {shouldPay ? (
          <Box
            bgcolor={COLORS.SECONDARY}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Box mb={2}>
              <Text color={COLORS.WHITE}>
                As you have chosen premium plan, please proceed to the checkout.
              </Text>
            </Box>
            <Box mb={2}>
              <ButtonRounded
                disabled={!activeSubscription.quickpayLink}
                label="Proceed to checkout"
                labelBold
                color={COLORS.BUTTON_PRIMARY}
                onClick={() => {
                  window.open(activeSubscription.quickpayLink, "_self");
                }}
              />
            </Box>
            <Box>
              <ButtonRounded
                label="Downgrade to free"
                disabled={isDowngrading}
                onClick={downgradeToFree}
              />
            </Box>
          </Box>
        ) : (
          <Switch>
            <Route exact path="/">
              <GTO
                closeGTOTour={() => setIsGTOTourOpened(false)}
                isGTOTourOpened={isGTOTourOpened}
              />
            </Route>
            <Route path={SETTINGS}>
              <Settings />
            </Route>
            <Route exact path="/cgu">
              <TermsAndConditions />
            </Route>
            <Route exact path={CONTACT_US}>
              <ContactUs />
            </Route>
          </Switch>
        )}
      </Box>
    </SnackbarProvider>
  );
}

const UnloggedShell = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { access_token } = useSelector(accountSelector);

  const refresh_token = Cookies.get("refresh_token");
  const userId = Cookies.get("userId");

  useEffect(() => {
    if (refresh_token && userId) {
      dispatch(refreshToken({ refresh_token, userId }));
    }
  }, []);

  useEffect(() => {
    if (access_token && ["/login", "/signup"].includes(location.pathname)) {
      history.push("/");
    }
  }, [access_token]);

  if (refresh_token && !access_token) return <FullScreenLoader />;

  return (
    <>
      <Switch>
        <Route exact path={LOGIN}>
          <Login />
        </Route>
        <Route exact path={SIGNUP}>
          <Signup />
        </Route>
        <Route exact path={TERMS_AND_CONDITIONS}>
          <TermsAndConditions />
        </Route>
        <Route path={ROOT}>
          {refresh_token ? (
            <LoggedShell />
          ) : (
            <Redirect
              to={{
                pathname: LOGIN,
                state: {
                  from: location,
                },
              }}
            />
          )}
        </Route>
      </Switch>
      <CookieBanner />
    </>
  );
};

export default function App() {
  return (
    <ReduxProvider store={store}>
      <ConnectedRouter history={history}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <UnloggedShell />
          </ThemeProvider>
        </StylesProvider>
      </ConnectedRouter>
    </ReduxProvider>
  );
}
