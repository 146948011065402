export enum PlanType {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
}

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  ENDED = "ENDED",
  IN_ERROR = "IN_ERROR",
}
