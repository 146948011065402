import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "api";
import { State } from "./index";

export const fetchActiveSubscription = createAsyncThunk(
  "fetchActiveSubscription",
  async (userId: string) => {
    try {
      return await API.getUserSubscription(userId, "1");
    } catch (e) {
      if (e.response.status === 404) {
        return await API.createUserSubscription("1");
      }
    }
  }
);

export const subscription = createSlice({
  name: "subscription",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchActiveSubscription.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const getActiveSubscription = (state: State) => state.subscription;

export default subscription;
