import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
// import { ButtonGoogleSigninSmall } from "ui/ButtonGoogleSignin";
// import ButtonRounded from "ui/ButtonRounded";
// import Text from "ui/Text";
// import TextFieldLight from "ui/TextFieldLight";
// import TextLink from "ui/TextLink";
import { COLORS } from "utils/theme";
import WIP from "./WIP";

import flopClubsSvg from "assets/images/flop_clubs.svg";
import loginSmallBgSvg from "assets/images/login_small_bg.svg";

const useStyles = makeStyles({
  typoRoundedText: {
    fontFamily: "Typo Round Regular",
  },
  wrapperBg: {
    backgroundImage: `url(${loginSmallBgSvg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
});

const LoginSmall = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.wrapperBg}
      sx={{
        alignItems: "center",
        bgcolor: COLORS.SECONDARY,
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          mt: "40px",
          mb: "12px",
        }}
      >
        <img
          alt=""
          src={flopClubsSvg}
          style={{ position: "absolute", width: "50px" }}
        />
        <img
          alt=""
          src={flopClubsSvg}
          style={{ filter: "blur(5px)", width: "50px" }}
        />
      </Box>
      <Box
        sx={{
          mb: "112px",
        }}
      >
        <Typography
          className={classes.typoRoundedText}
          color="textPrimary"
          variant="h6"
        >
          Welcome to <strong>FLOP GTO</strong>
        </Typography>
      </Box>
      <WIP isSmall />
      {/* <Box
        sx={{
          alignSelf: "stretch",
          px: "40px",
        }}
      >
        <Box
          sx={{
            mb: "24px",
          }}
        >
          <TextFieldLight
            label="Email address"
            onChange={() => {}}
            value={""}
          />
        </Box>
        <Box
          sx={{
            mb: "8px",
          }}
        >
          <TextFieldLight label="Password" onChange={() => {}} value={""} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: "40px",
          }}
        >
          <TextLink color={COLORS.WHITE} variant="body2">
            I forgot my password
          </TextLink>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          alignSelf: "stretch",
          display: "flex",
          justifyContent: "center",
          mb: "24px",
        }}
      >
        <Box
          sx={{
            flex: "0 1 132px",
          }}
        >
          <ButtonRounded
            color={COLORS.ACCENT_1}
            label="SIGN IN"
            onClick={() => {}}
          />
        </Box>
        <Box
          sx={{
            mx: "16px",
          }}
        >
          <Typography color="textPrimary">or</Typography>
        </Box>
        <ButtonGoogleSigninSmall />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: "48px",
        }}
      >
        <Typography color="textPrimary" variant="body2">
          Don't have an account yet?
        </Typography>
        &nbsp;
        <TextLink color={COLORS.ACCENT_1} variant="body2">
          Create one
        </TextLink>
      </Box>
      <Box
        sx={{
          alignItems: "flex-end",
          display: "flex",
          flex: 1,
          justifyContent: "center",
          mb: "8px",
        }}
      >
        <Text color={COLORS.BLUE_6} variant="caption">
          By creating an account, I accept the{" "}
          <Link component={RouterLink} to="/terms-and-conditions" underline="none">
            <TextLink>terms &amp; conditions</TextLink>
          </Link>
        </Text>
      </Box> */}
    </Box>
  );
};

export default LoginSmall;
