import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LoginSmall from "pages/Login/Login_small";

type Props = {
  large: React.ReactElement;
  medium?: React.ReactElement;
  small?: React.ReactElement;
};

const ResponsiveWrapper: React.FC<Props> = ({
  large,
  medium = <LoginSmall />,
  small = <LoginSmall />,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  return isLg ? large : isMd ? medium : small;
};

export default ResponsiveWrapper;
