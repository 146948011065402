import React from "react";
import { noop } from "lodash/fp";
import Box from "@material-ui/core/Box";

import { COLORS } from "utils/theme";

import loopPng from "assets/icons/loop.png";
import redoPng from "assets/icons/redo.png";
import undoPng from "assets/icons/undo.png";

type Props = {
  canRedo: boolean;
  canReset: boolean;
  canUndo: boolean;
  redoAction: () => void;
  resetSimulation: () => void;
  undoAction: () => void;
};

const BranchTraveler: React.FC<Props> = ({
  canRedo,
  canReset,
  canUndo,
  redoAction,
  resetSimulation,
  undoAction,
}) => {
  return (
    <Box
      className="branch-traveler-tour"
      sx={{
        bgcolor: COLORS.PRIMARY,
        borderRadius: "1vw",
        display: "flex",
        px: "0.83vw",
      }}
    >
      <Box
        onClick={canUndo ? undoAction : noop}
        sx={{
          alignItems: "center",
          borderRight: `0.05vw solid ${COLORS.MUTED_PRIMARY}`,
          ...(canUndo && { cursor: "pointer" }),
          display: "flex",
          my: "0.4vw",
          pr: "0.4vw",
        }}
      >
        <img
          alt="undo action"
          src={undoPng}
          style={{ ...(!canUndo && { opacity: 0.3 }), width: "1.25vw" }}
        />
      </Box>
      <Box
        onClick={canRedo ? redoAction : noop}
        sx={{
          alignItems: "center",
          borderRight: `0.05vw solid ${COLORS.MUTED_PRIMARY}`,
          ...(canRedo && { cursor: "pointer" }),
          display: "flex",
          my: "0.4vw",
          px: "0.4vw",
        }}
      >
        <img
          alt="redo action"
          src={redoPng}
          style={{ ...(!canRedo && { opacity: 0.3 }), width: "1.25vw" }}
        />
      </Box>
      <Box
        onClick={resetSimulation}
        sx={{
          alignItems: "center",
          ...(canReset && { cursor: "pointer" }),
          display: "flex",
          my: "0.4vw",
          pl: "0.4vw",
        }}
      >
        <img
          alt="reset action"
          src={loopPng}
          style={{ ...(!canReset && { opacity: 0.3 }), width: "1.25vw" }}
        />
      </Box>
    </Box>
  );
};

export default BranchTraveler;
