import React, { useState } from "react";
import { Box } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import { PlanType } from "types";
import { COLORS } from "utils/theme";
import ButtonRounded from "ui/ButtonRounded";
import Radio from "ui/Radio";
import Text from "ui/Text";

export interface PlanSelectorProps {
  defaultValue: PlanType;
  onPlanSelect: any;
}

export default function PlanSelector({
  defaultValue,
  onPlanSelect,
}: PlanSelectorProps) {
  const [selectedPlan, setSelectedPlan] = useState(defaultValue);

  const isSelectedFree = selectedPlan === PlanType.FREE;
  const isSelectedPremium = selectedPlan === PlanType.PREMIUM;

  function selectPlan() {
    onPlanSelect(selectedPlan);
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box
        mb={2}
        bgcolor={COLORS.PRIMARY}
        borderRadius="16px"
        px={4}
        py={2}
        style={
          isSelectedPremium
            ? {
                opacity: 0.5,
              }
            : {}
        }
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Radio
                isChecked={isSelectedFree}
                onSelect={() => setSelectedPlan(PlanType.FREE)}
              />
            </Box>
            <Box onClick={() => setSelectedPlan(PlanType.FREE)}>
              <Text fontSize="24px" color={COLORS.WHITE} pointer>
                Free
              </Text>
            </Box>
          </Box>
          <Box>
            <Text fontSize="16px" color={COLORS.BLUE_6}>
              0 € / month
            </Text>
          </Box>
        </Box>
        <Box>
          <Text fontSize="18px" color={COLORS.WHITE}>
            Free forever. Get started and discover the basics of GTO.
          </Text>
        </Box>
        <Box
          display={isSelectedFree ? "flex" : "none"}
          flexDirection="column"
          justifyContent="center"
          mt={4}
        >
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              MTT 9 Max (preflop) - 10, 12 & 15 bbs
            </Text>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              MTT 6 Max (preflop) - 10, 12 & 15 bbs
            </Text>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              Spin Full (preflop) - 8 & 10 bbs
            </Text>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              Spins Simple (preflop) - 8 & 10 bbs
            </Text>
          </Box>
        </Box>
      </Box>
      <Box mb={2} bgcolor={COLORS.PRIMARY} borderRadius="16px" px={4} py={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Radio
                isChecked={isSelectedPremium}
                onSelect={() => setSelectedPlan(PlanType.PREMIUM)}
              />
            </Box>
            <Box onClick={() => setSelectedPlan(PlanType.PREMIUM)}>
              <Text fontSize="24px" color={COLORS.WHITE} pointer>
                Premium
              </Text>
            </Box>
          </Box>
          <Box>
            <Text fontSize="16px" color={COLORS.BLUE_6}>
              30 € / month (no commitment)
            </Text>
          </Box>
        </Box>
        <Box>
          <Text fontSize="18px" color={COLORS.WHITE}>
            Unlock all GTO solutions available and step up your game.
          </Text>
        </Box>
        <Box
          display={isSelectedPremium ? "flex" : "none"}
          flexDirection="column"
          justifyContent="center"
          mt={4}
        >
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              MTT 9 Max (preflop) - 17, 20, 25, 30, 40, 50, 60 & 80 bbs
            </Text>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              MTT 6 Max (preflop) - 17, 25 & 40 bbs
            </Text>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              MTT BvB with limps (6-max) : 10 - 12 & 15 bb
            </Text>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              MTT BvB with limps (9-max) : 10 - 12 - 15 - 17 - 20 - 25 - 30 - 40
              - 50 - 60 - 80 & 100 bb
            </Text>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              Spin Full (preflop) - 15, 17, 20 & 25 bbs
            </Text>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              Spin Simple (preflop) - 15, 17, 20 & 25 bbs
            </Text>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              Cash Game NL100 (preflop) - 100bbs
            </Text>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={1} color={COLORS.GREEN}>
              <Check />
            </Box>
            <Text color={COLORS.WHITE} fontSize="18px">
              Cash Game NL500 (preflop) - 100bbs
            </Text>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box width="fit-content">
          <ButtonRounded
            color={COLORS.BUTTON_PRIMARY}
            label={isSelectedFree ? "Get started for free" : "Go premium"}
            labelBold
            onClick={selectPlan}
          />
        </Box>
      </Box>
    </Box>
  );
}
