import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from "connected-react-router";
import { Location } from "history";
import history from "../history";
import account, { AccountState } from "./account";
import error, { ErrorState } from "./error";
import loading, { LoadingState } from "./loading";
import subscription from "./subscription";

export type State = {
  account: AccountState;
  error: ErrorState;
  loading: LoadingState;
  router: RouterState & { location: Location<{ from?: Location }> };
  subscription: any;
};

const store = configureStore({
  reducer: combineReducers({
    account: account.reducer,
    error,
    loading,
    router: connectRouter(history),
    subscription: subscription.reducer,
  }),
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    routerMiddleware(history),
    ...(process.env.NODE_ENV !== "production" ? [logger] : []),
  ],
});

export default store;
