import React from "react";
import Box from "@material-ui/core/Box";

import AllInclusive from "@material-ui/icons/AllInclusive";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import DragHandle from "@material-ui/icons/DragHandle";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";

import Text from "ui/Text";
import { Action } from "utils/models";
import { COLORS, getActionLabel, RAISE_ACTIONS_REGEX } from "utils/theme";

type Props = {
  actions: Action[];
  onSelectAction: (branchId: number) => void;
};

export const ActionIcon: React.FC<{ action: string }> = ({ action }) =>
  action === "FOLD" ? (
    <Close style={{ fontSize: "1.25vw" }} />
  ) : action === "CALL" ? (
    <DragHandle style={{ fontSize: "1.25vw" }} />
  ) : action === "CHECK" ? (
    <VisibilityOutlined style={{ fontSize: "1.25vw" }} />
  ) : action.search(RAISE_ACTIONS_REGEX) !== -1 ? (
    <Add style={{ fontSize: "1.25vw" }} />
  ) : (
    <AllInclusive style={{ fontSize: "1.25vw" }} />
  );

const ActionsBar: React.FC<Props> = ({ actions, onSelectAction }) => {
  return (
    <Box
      className="actions-bar-tour"
      sx={{
        borderRadius: "0.4vw",
        bgcolor: COLORS.PRIMARY,
        display: "flex",
        px: "0.4vw",
      }}
    >
      {actions.map(({ action, actionValueInBb, branchId }, index) => (
        <Box
          key={`action-${branchId}-${index}`}
          sx={{
            ...(index !== actions.length - 1 && {
              borderRight: `0.05vw solid ${COLORS.MUTED_PRIMARY}`,
            }),
            my: "0.4vw",
            px: actions.length > 6 ? "0.3vw" : "0.6vw",
            zIndex: 99,
          }}
        >
          <Box
            onClick={() => onSelectAction(branchId)}
            sx={{
              cursor: "pointer",
              py: "0.4vw",
              px: actions.length > 6 ? "0.3vw" : "0.6vw",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                color: COLORS.WHITE,
                display: "flex",
              }}
            >
              {actions.length < 4 && (
                <Box sx={{ display: "flex", mr: "0.4vw" }}>
                  <ActionIcon action={action} />
                </Box>
              )}
              <Text fontSize="0.85vw" fontWeight="bold" whiteSpace="nowrap">
                {getActionLabel(action, actionValueInBb)}
              </Text>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ActionsBar;
