import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import ButtonRounded from "ui/ButtonRounded";
import Text from "ui/Text";
import { Solution } from "utils/models";
import { COLORS } from "utils/theme";
import premiumPng from "assets/icons/premium.png";
import { SETTINGS_SUBSCRIBE } from "constants/routes";

type GameTypeSelectorProps = {
  onSelectGameType: (gameType: string) => void;
  selectedGameType: string;
  solutions: { [key: string]: Solution[] };
};

const useSelectStyles = makeStyles({
  iconFilled: {
    height: "1.25vw",
    right: "0.36vw",
    top: "calc(50% - 0.62vw)",
    width: "1.25vw",
  },
  root: {
    alignItems: "center",
    borderRadius: "1vw",
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY,
    display: "flex",
    height: "2.1vw",
    lineHeight: "0.94vw",
    padding: "0px",
    paddingLeft: "0.83vw",
    width: "10.4vw",
  },
  select: {
    "&:focus": {
      backgroundColor: COLORS.WHITE,
      borderRadius: "1vw",
    },
  },
});

const useDialogStyles = makeStyles({
  paper: {
    borderRadius: "16px",
    maxWidth: 800,
    padding: "16px 8px",
  },
});

const useMenuStyles = makeStyles({
  paper: {
    borderRadius: "1vw",
  },
  list: {
    color: COLORS.PRIMARY,
  },
});

export default function GameTypeSelector({
  onSelectGameType,
  selectedGameType,
  solutions,
}: GameTypeSelectorProps) {
  const selectClasses = useSelectStyles();
  const menuClasses = useMenuStyles();

  const [isPremiumModalOpen, setPremiumModalOpen] = useState(false);

  function openPremiumModal() {
    setPremiumModalOpen(true);
  }

  function closePremiumModal() {
    setPremiumModalOpen(false);
  }

  function hasAccessToSolution(name: string) {
    return solutions[name].find(({ id }) => !!id);
  }

  const dialogClasses = useDialogStyles();

  return (
    <Box sx={{ pl: "0.83vw" }}>
      <Dialog open={isPremiumModalOpen} classes={dialogClasses}>
        <DialogContent>
          <Box mb={2}>
            <Text color={COLORS.BLACK} fontSize="30px">
              This mode is reserved only to Premium users
            </Text>
          </Box>
          <Box>
            <Text color={COLORS.BLACK} fontSize="20px">
              Premium includes the following modes:
            </Text>
          </Box>
          <Box mb={2}>
            <Text color={COLORS.BLACK}>
              <ul>
                <li>Cash NL 100</li>
                <li>Cash NL 200</li>
                <li>Cash NL 500</li>
                <li>MTT NLH 6-max : 17 - 20 - 25 - 40 - 50 - 60 - 80 bb</li>
                <li>
                  MTT NLH 9-Max : 17 - 20 - 25 - 30 - 40 - 50 - 60 - 80 bb
                </li>
                <li>
                  MTT HU : 10 - 12 - 15 - 17 - 20 - 27 - 35 - 50 - 75 - 100 bb
                </li>
                <li>MTT BvB with limps (6-max) : 10 - 12 - 15 bb</li>
                <li>
                  MTT BvB with limps (9-max) : 10 - 12 - 15 - 17 - 20 - 25 - 30
                  - 40 - 50 - 60 - 80 - 100 bb
                </li>
                <li>Spins Full : 12 - 15 - 17 - 20 - 25 bb</li>
                <li>Spins Simple : 12 - 15 - 17 - 20 - 25 bb</li>
              </ul>
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box mb={1}>
              <Link to={SETTINGS_SUBSCRIBE}>
                <ButtonRounded
                  onClick={() => {}}
                  color={COLORS.ERROR}
                  label="Upgrade now!"
                  labelColor={COLORS.WHITE}
                />
              </Link>
            </Box>
            <ButtonRounded
              color={COLORS.WHITE}
              label="Stay Freemium"
              labelColor={COLORS.SECONDARY}
              borderColor={COLORS.SECONDARY}
              onClick={closePremiumModal}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <Box sx={{ mb: "0.4vw", pl: "0.2vw" }}>
        <Text color={COLORS.WHITE} fontSize="0.85vw" fontWeight={700}>
          1 - Select game type
        </Text>
      </Box>
      <Select
        classes={selectClasses}
        className="game-type-tour"
        disableUnderline
        MenuProps={{
          classes: menuClasses,
          elevation: 0,
          MenuListProps: { disablePadding: true },
        }}
        onChange={({ target: { value } }) => {
          if (!hasAccessToSolution(value)) {
            openPremiumModal();
          } else {
            onSelectGameType(value);
          }
        }}
        variant="filled"
        value={selectedGameType}
      >
        {Object.keys(solutions)
          .sort()
          .map((gameFormat) => {
            const disabled = !hasAccessToSolution(gameFormat);
            const [gameType, gameVariant] = gameFormat.split("-");

            return (
              <MenuItem key={gameFormat} value={gameFormat}>
                <Box
                  sx={{
                    alignItems: "baseline",
                    display: "flex",
                    fontSize: "0.85vw",
                    ...(disabled && { opacity: 0.4 }),
                    "&:focus": { outline: "none" },
                  }}
                >
                  <Box sx={{ mr: "4px" }}>{gameType}</Box>
                  <Text fontSize="13px">({gameVariant})</Text>
                </Box>
                {disabled && (
                  <img
                    alt="Premium icon"
                    src={premiumPng}
                    style={{
                      position: "absolute",
                      right: "0.5vw",
                      top: "0.4vw",
                      width: "1.1vw",
                    }}
                  />
                )}
              </MenuItem>
            );
          })}
      </Select>
    </Box>
  );
}
