import React, { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import ResponsiveWrapper from "ui/ResponsiveWrapper";
import LoginLarge from "./Login_large";
//import LoginMedium from "./Login_medium";
import LoginSmall from "./Login_small";

const Login = () => {
  useEffect(() => {
    mixpanel.reset();
  }, []);

  return (
    <ResponsiveWrapper
      large={<LoginLarge />}
      medium={<LoginLarge />}
      small={<LoginSmall />}
    />
  );
};

export default Login;
