import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import { COLORS } from "utils/theme";

type Props = {
  borderColor?: string;
  buttonHeight?: string;
  id?: string;
  color?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  label: string;
  labelBold?: boolean;
  labelColor?: string;
  labelUppercased?: boolean;
  onClick: () => void;
  startIcon?: React.ReactNode;
  type?: ButtonProps["type"];
};

type ButtonStyle = {
  borderColor?: string;
  buttonHeight?: string;
  color?: string;
  fullWidth?: boolean;
  labelBold?: boolean;
  labelColor?: string;
  labelUppercased?: boolean;
};

const useStyles = makeStyles({
  root: ({
    borderColor,
    color,
    fullWidth = true,
    buttonHeight = "38px",
  }: ButtonStyle) => ({
    backgroundColor: color,
    border: `2px solid ${borderColor}`,
    borderRadius: "18px",
    height: buttonHeight,
    ...(fullWidth && { width: "100%" }),
    "&:hover": {
      backgroundColor: color,
      opacity: 0.8,
    },
    "&.Mui-disabled": {
      backgroundColor: COLORS.BLUE_4,
    },
  }),
  label: ({ labelBold, labelColor, labelUppercased = true }: ButtonStyle) => ({
    color: labelColor,
    ...(labelBold && {
      fontWeight: "bold",
    }),
    ...(!labelUppercased && {
      textTransform: "none",
    }),
  }),
});

type LoaderStyle = {
  color?: string;
};

const useLoaderStyles = makeStyles({
  root: ({ color }: LoaderStyle) => ({
    color,
  }),
});

export default function ButtonRounded({
  borderColor,
  buttonHeight,
  color,
  disabled,
  id,
  fullWidth,
  isLoading,
  label,
  labelBold,
  labelColor = COLORS.WHITE,
  labelUppercased,
  onClick,
  startIcon,
  type,
}: Props) {
  const classes = useStyles({
    borderColor,
    buttonHeight,
    color,
    fullWidth,
    labelBold,
    labelColor,
    labelUppercased,
  });
  const loaderClasses = useLoaderStyles({ color: labelColor });
  return (
    <Button
      classes={classes}
      disabled={disabled}
      disableElevation
      id={id}
      {...(!isLoading && { onClick })}
      startIcon={startIcon}
      type={type}
      variant="contained"
    >
      {isLoading ? (
        <CircularProgress classes={loaderClasses} size={20} />
      ) : (
        label
      )}
    </Button>
  );
}
