import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import { COLORS } from "utils/theme";

type Props = {
  endAdornment?: React.ReactNode;
  error?: string;
  helperText?: string;
  label: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  type?: string;
  value: string;
};

const useInputStyles = makeStyles((theme) => ({
  root: ({ error }: { error?: string }) => ({
    backgroundColor: COLORS.BLUE_5,
    ...(error && { border: `2px solid ${theme.palette.error.main}` }),
    borderRadius: "4px",
    color: COLORS.BLUE_1,
    "&:hover": {
      backgroundColor: COLORS.BLUE_5,
    },
    "&.Mui-focused": {
      backgroundColor: COLORS.BLUE_5,
    },
  }),
}));

const useInputLabelStyles = makeStyles({
  root: {
    color: COLORS.BLUE_3,
  },
});

const TextFieldLight: React.FC<Props> = ({
  endAdornment,
  error,
  helperText,
  label,
  onBlur,
  onChange,
  type,
  value,
}) => {
  const inputClasses = useInputStyles({ error });
  const inputLabelClasses = useInputLabelStyles();

  return (
    <TextField
      error={!!error}
      fullWidth
      InputProps={{
        classes: inputClasses,
        disableUnderline: true,
        endAdornment,
      }}
      InputLabelProps={{ classes: inputLabelClasses }}
      helperText={error || helperText}
      label={label}
      onBlur={onBlur}
      onChange={({ target: { value } }) => onChange(value)}
      type={type}
      value={value}
      variant="filled"
    />
  );
};

export default TextFieldLight;
