import React, { useCallback, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";

import API from "api";
import ButtonRounded from "ui/ButtonRounded";
import Text from "ui/Text";
import TextFieldLight from "ui/TextFieldLight";
import { COLORS } from "utils/theme";
import { isEmail, isRequired, validator } from "utils";
import { Profile } from "utils/models";

type Props = {
  fetchProfile: () => void;
  profile: Profile;
  userId: string;
};

const AccountInfosForm: React.FC<Props> = ({
  fetchProfile,
  profile,
  userId,
}) => {
  const [firstName, setFirstName] = useState(profile.firstName || "");
  const [lastName, setLastName] = useState(profile.lastName || "");
  const [email, setEmail] = useState(profile.email);
  const [emailError, setEmailError] = useState("");
  useEffect(() => {
    if (emailError) {
      setEmailError("");
    }
  }, [email]);

  const isEmailValid = useCallback(() => {
    const emailError = validator([isRequired, isEmail])(email);
    if (emailError) {
      setEmailError(emailError);
    }
  }, [email]);

  const hasAccountFormChanged =
    email !== profile.email ||
    firstName !== (profile.firstName || "") ||
    lastName !== (profile.lastName || "");

  const canSubmitAccountForm = hasAccountFormChanged && !emailError;

  const onCancelAccountForm = () => {
    setEmail(profile.email);
    setFirstName(profile.firstName || "");
    setLastName(profile.lastName || "");
  };

  const onSubmitAccountForm = async () => {
    await API.updateProfile(userId, email, firstName, lastName);
    fetchProfile();
  };

  return (
    <>
      <Box sx={{ mb: "40px" }}>
        <Text color={COLORS.TITLE_1} fontSize="22px">
          Account information
        </Text>
      </Box>
      <Box sx={{ mb: "32px" }}>
        <TextFieldLight
          label="First name"
          onChange={setFirstName}
          value={firstName}
        />
      </Box>
      <Box sx={{ mb: "32px" }}>
        <TextFieldLight
          label="Last name"
          onChange={setLastName}
          value={lastName}
        />
      </Box>
      <Box sx={{ mb: "24px" }}>
        <TextFieldLight
          error={emailError}
          label="Email address"
          onBlur={isEmailValid}
          onChange={setEmail}
          value={email}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {hasAccountFormChanged && (
          <Box sx={{ mr: "16px" }}>
            <ButtonRounded
              borderColor={COLORS.ACCENT_1}
              color="transparent"
              fullWidth={false}
              label="Cancel"
              labelColor={COLORS.ACCENT_1}
              labelUppercased={false}
              onClick={onCancelAccountForm}
            />
          </Box>
        )}
        <ButtonRounded
          disabled={!canSubmitAccountForm}
          color={COLORS.ACCENT_1}
          fullWidth={false}
          label="Save changes"
          labelUppercased={false}
          onClick={onSubmitAccountForm}
        />
      </Box>
    </>
  );
};

export default AccountInfosForm;
