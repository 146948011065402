import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@material-ui/core";
import Link from "@material-ui/core/Link";

import { TERMS_AND_CONDITIONS } from "constants/routes";
import Text from "ui/Text";
import TextLink from "ui/TextLink";
import { COLORS } from "utils/theme";

export interface UnloggedWrapperProps {
  children: React.ReactElement | React.ReactElement[];
}

export default function UnloggedWrapper({ children }: UnloggedWrapperProps) {
  return (
    <Box
      sx={{
        bgcolor: COLORS.SECONDARY,
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        {children}
      </Box>
      <Box display="flex" justifyContent="center" mb={1}>
        <Text color={COLORS.BLUE_6}>
          By creating an account, I accept the{" "}
          <Link component={RouterLink} to={TERMS_AND_CONDITIONS}>
            <TextLink color={COLORS.BLUE_6}>terms & conditions</TextLink>
          </Link>
        </Text>
      </Box>
    </Box>
  );
}
