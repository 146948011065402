export const isRequired = (value: any) =>
  value ? "" : "This field is required";

export const isEmail = (value: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? ""
    : "The email is not valid";

export const isPassword = (value: string) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S_]{6,}$/g.test(value)
    ? ""
    : "The password is not valid";

export const validator = (rules: ((value: any) => string)[]) => (value: any) =>
  rules.reduce((acc: string, rule) => acc || rule(value), "");
