import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LoginForm from "components/LoginForm";
import { COLORS } from "utils/theme";

import flopSpadesSvg from "assets/images/flop_spades.svg";

const useStyles = makeStyles({
  card: {
    borderRadius: "16px",
  },
  typoRoundedText: {
    fontFamily: "Typo Round Regular",
  },
  welcomeWrapper: {
    background: "linear-gradient(153.33deg, #F5AA52 0%, #FE5F55 100%)",
  },
});

const Login = () => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        bgcolor: COLORS.SECONDARY,
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Card className={classes.card} raised>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <LoginForm />
            <Box
              className={classes.welcomeWrapper}
              sx={{
                alignItems: "center",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                width: 433,
              }}
            >
              <Box mb={1}>
                <img
                  alt=""
                  src={flopSpadesSvg}
                  style={{ position: "absolute" }}
                />
                <img
                  alt=""
                  src={flopSpadesSvg}
                  style={{ filter: "blur(5px)" }}
                />
              </Box>
              <Box>
                <Typography className={classes.typoRoundedText} variant="h6">
                  Welcome to <strong>FLOP GTO</strong>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default Login;
