import { useCallback, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import API from "api";
import { SETTINGS, SETTINGS_SUBSCRIBE } from "constants/routes";
import FullScreenLoader from "components/FullScreenLoader";
import { accountSelector } from "store/account";
import { COLORS } from "utils/theme";
import { Profile } from "utils/models";
import MyAccountTab from "./components/MyAccountTab";
import PlanAndPaymentTab from "./components/PlanAndPaymentTab";
import { Grid } from "@material-ui/core";

const useTabsStyles = makeStyles({
  root: {
    backgroundColor: COLORS.WHITE,
    color: COLORS.BLUE_1,
  },
  indicator: {
    backgroundColor: COLORS.BLUE_1,
  },
});

export default function Settings() {
  const history = useHistory();
  const location = useLocation();

  const tabsClasses = useTabsStyles();
  const { userId } = useSelector(accountSelector);

  const [profile, setProfile] = useState<Profile>();

  const fetchProfile = useCallback(async () => {
    setProfile(await API.getProfile(userId));
  }, []);

  function goToProfile() {
    history.push(SETTINGS);
  }

  function goToPayment(replace?: boolean) {
    if (replace) {
      history.replace(SETTINGS_SUBSCRIBE);
    } else {
      history.push(SETTINGS_SUBSCRIBE);
    }
  }

  useEffect(() => {
    fetchProfile();
  }, []);

  if (!profile) return <FullScreenLoader />;

  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      bgcolor={COLORS.SECONDARY}
    >
      <Grid item xs={11} sm={10} md={8} lg={6}>
        <Box width="100%">
          <Tabs
            classes={tabsClasses}
            value={location.pathname === SETTINGS ? 0 : 1}
            variant="fullWidth"
            onChange={(_, value) => {
              if (value === 0) {
                goToProfile();
              }

              if (value === 1) {
                goToPayment();
              }
            }}
          >
            <Tab label="My Account" />
            <Tab label="Plan & payment" />
          </Tabs>
        </Box>
        <Box
          width="100%"
          minHeight={400}
          bgcolor={COLORS.WHITE}
          display="flex"
          py={4}
        >
          <Switch>
            <Route exact path={SETTINGS}>
              <MyAccountTab
                fetchProfile={fetchProfile}
                profile={profile}
                userId={userId}
              />
            </Route>
            <Route exact path={SETTINGS_SUBSCRIBE}>
              <PlanAndPaymentTab />
            </Route>
          </Switch>
        </Box>
      </Grid>
    </Grid>
  );
}
