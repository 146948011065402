import React, { useEffect, useState } from "react";
import { COLORS } from "../../../utils/theme";
import Box from "@material-ui/core/Box";
import AccountInfosForm from "../../../components/AccountInfosForm";
import Text from "../../../ui/Text";
import TextFieldLight from "../../../ui/TextFieldLight";
import ButtonRounded from "../../../ui/ButtonRounded";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import { isPassword, validator } from "../../../utils";
import API from "../../../api";
import { makeStyles } from "@material-ui/core/styles";

export const dataTestIds = {
  root: "TODO",
};

export interface MyAccountTabProps {
  fetchProfile: any;
  profile: any;
  userId: any;
}

const useEndAdornmentStyles = makeStyles({
  root: {
    cursor: "pointer",
    fill: COLORS.BLUE_3,
  },
});

export default function MyAccountTab({
  fetchProfile,
  profile,
  userId,
}: MyAccountTabProps) {
  const endAdornmentClasses = useEndAdornmentStyles();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");
  useEffect(() => {
    if (confirmNewPasswordError) {
      setNewPasswordError("");
      setConfirmNewPasswordError("");
    }
  }, [newPassword, confirmNewPassword]);

  const hasPasswordFormChanged = newPassword && confirmNewPassword;

  const canSubmitPasswordForm =
    hasPasswordFormChanged && !newPasswordError && !confirmNewPasswordError;

  const onCancelPasswordForm = () => {
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const toggleIsPasswordVisible = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const PasswordFieldsEndAdornment = isPasswordVisible
    ? VisibilityOutlined
    : VisibilityOffOutlined;

  const onSubmitPasswordForm = async () => {
    const newPasswordError = validator([isPassword])(newPassword);
    const confirmNewPasswordError = validator([
      (confirmNewPassword) =>
        newPassword === confirmNewPassword ? "" : "Password does not match",
    ])(confirmNewPassword);
    if (newPasswordError) {
      setNewPasswordError(newPasswordError);
      // setAnchorEl(passwordInfoIconRef.current);
    } else if (confirmNewPasswordError) {
      setNewPasswordError(confirmNewPasswordError);
      setConfirmNewPasswordError(confirmNewPasswordError);
    } else {
      await API.putNewPassword(userId, newPassword);
      setNewPassword("");
      setConfirmNewPassword("");
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" flex={1} px={4}>
        <AccountInfosForm
          fetchProfile={fetchProfile}
          profile={profile}
          userId={userId}
        />
      </Box>
      <Box display="flex" flexDirection="column" flex={1} px={4}>
        <Box mb={5}>
          <Text color={COLORS.TITLE_1} fontSize="22px">
            Change password
          </Text>
        </Box>
        <Box mb={4}>
          <TextFieldLight
            endAdornment={
              <PasswordFieldsEndAdornment
                classes={endAdornmentClasses}
                onClick={toggleIsPasswordVisible}
              />
            }
            error={newPasswordError}
            label="New password"
            onChange={setNewPassword}
            {...(!isPasswordVisible && { type: "password" })}
            value={newPassword}
          />
        </Box>
        <Box sx={{ mb: "24px" }}>
          <TextFieldLight
            endAdornment={
              <PasswordFieldsEndAdornment
                classes={endAdornmentClasses}
                onClick={toggleIsPasswordVisible}
              />
            }
            error={confirmNewPasswordError}
            label="Confirm new password"
            onChange={setConfirmNewPassword}
            {...(!isPasswordVisible && { type: "password" })}
            value={confirmNewPassword}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {hasPasswordFormChanged && (
            <Box sx={{ mr: "16px" }}>
              <ButtonRounded
                borderColor={COLORS.ACCENT_1}
                color="transparent"
                fullWidth={false}
                label="Cancel"
                labelColor={COLORS.ACCENT_1}
                labelUppercased={false}
                onClick={onCancelPasswordForm}
              />
            </Box>
          )}
          <ButtonRounded
            color={COLORS.ACCENT_1}
            disabled={!canSubmitPasswordForm}
            fullWidth={false}
            label="Change password"
            labelUppercased={false}
            onClick={onSubmitPasswordForm}
          />
        </Box>
      </Box>
    </>
  );
}
