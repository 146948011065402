import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Variant } from "@material-ui/core/styles/createTypography";

type Props = {
  color?: string;
  titleVariant?: Variant;
  title?: string;
  content?: string;
};

type TypographyStyle = {
  color?: string;
};

const useStyles = makeStyles({
  root: ({ color }: TypographyStyle) => ({
    color,
  }),
});

const TextSection: React.FC<Props> = ({
  color,
  titleVariant,
  title,
  content,
}) => {
  const classes = useStyles({ color });
  return (
    <Box
      sx={{
        py: 2,
      }}
    >
      {title && (
        <Typography
          gutterBottom={true}
          classes={classes}
          variant={titleVariant}
        >
          {title}
        </Typography>
      )}

      <Typography paragraph={true} classes={classes}>
        {/* Just wanted to allow to break lines into the T&C */}
        {content &&
          content.split("\n").map((i, key) => {
            return <div key={key}>{i}</div>;
          })}
      </Typography>
    </Box>
  );
};

export default TextSection;
