import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import API from "api";
import moment from "moment";
import { useSnackbar } from "notistack";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import bankCard from "assets/icons/bank_card.svg";
import { CONTACT_US } from "constants/routes";
import { PlanType, SubscriptionStatus } from "types";
import ButtonRounded from "ui/ButtonRounded";
import Text from "ui/Text";
import { COLORS } from "utils/theme";
import PlanListItem from "./PlanListItem";

const useDialogStyles = makeStyles({
  paper: {
    borderRadius: "16px",
    maxWidth: 400,
    padding: "16px 8px",
  },
});

export default function PlanAndPaymentTab() {
  const { enqueueSnackbar } = useSnackbar();

  const [activeSubscription, setActiveSubscription] = useState<any>();
  const [isCancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(PlanType.FREE);
  const [plans, setPlans] = useState<Array<any>>([]);

  function openModal() {
    setCancellationModalOpen(true);
  }

  function closeModal() {
    setCancellationModalOpen(false);
  }

  async function cancelSubscription() {
    await API.cancelSubscription(activeSubscription.id);
    enqueueSnackbar(
      `Your membership will be stopped on ${moment(
        activeSubscription.subscriptionEndDate
      ).format("MM/DD/yyyy")}`,
      {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
      }
    );
    closeModal();
    await fetchActiveSubscription();
  }

  async function fetchActiveSubscription() {
    const userId: any = Cookies.get("userId");

    if (!userId) return;

    try {
      const activeSubscription = await API.getUserSubscription(userId, "1");
      setActiveSubscription(activeSubscription);

      setSelectedPlan(
        activeSubscription.status === SubscriptionStatus.ACTIVE ||
          activeSubscription?.status === SubscriptionStatus.CANCELLED ||
          activeSubscription?.status === SubscriptionStatus.IN_ERROR
          ? PlanType.PREMIUM
          : PlanType.FREE
      );
    } catch (e) {
      if (e.response.status === 404) {
        // Create a subscription
        setActiveSubscription(await API.createUserSubscription("1"));
      }
    }

    setPlans(await API.getPaymentPlans());
  }

  async function handleSubscriptionCancellation() {
    await API.stopSubscriptionCancellation(activeSubscription?.id);

    enqueueSnackbar(
      "Congratulation, you have successfully cancelled your account desactivation",
      {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
      }
    );
    await fetchActiveSubscription();
  }

  useEffect(() => {
    fetchActiveSubscription();
  }, []);

  const premiumPlan = plans.find((x) => x.name === "GTONLHPREMIUM");
  const isActivePremium =
    activeSubscription?.status === SubscriptionStatus.ACTIVE ||
    activeSubscription?.status === SubscriptionStatus.CANCELLED;
  const isError = activeSubscription?.status === SubscriptionStatus.IN_ERROR;

  const dialogClasses = useDialogStyles();

  return (
    <>
      <Dialog open={isCancellationModalOpen} classes={dialogClasses}>
        <DialogContent>
          <Box mb={4}>
            <Text color={COLORS.BLACK}>
              All payments and premium services will be stopped on{" "}
              {moment(activeSubscription?.subscriptionEndDate).format(
                "MM/DD/yyyy"
              )}
              .
            </Text>
            <Text color={COLORS.BLACK}>
              After this date, your account will be downgraded to the free tier
              and your data safely stored to make sure everything will still be
              there if you want to come back later.
            </Text>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              onClick={closeModal}
              style={{
                border: `2px solid ${COLORS.ERROR}`,
                borderRadius: "16px",
                color: COLORS.ERROR,
                padding: "2px 12px",
                textTransform: "unset",
              }}
            >
              I changed my mind
            </Button>
            <Button
              onClick={cancelSubscription}
              style={{
                backgroundColor: COLORS.ERROR,
                borderRadius: "16px",
                color: COLORS.WHITE,
                padding: "2px 12px",
                textTransform: "unset",
              }}
            >
              Stop my membership
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" px={4}>
            <Box mb={5}>
              <Text color={COLORS.TITLE_1} fontSize="22px">
                Membership plans
              </Text>
            </Box>
            {!plans.length ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container minHeight="72px" maxHeight="72px" mb={2}>
                  <PlanListItem
                    name="Free plan"
                    description="Free forever. Get started and discover the basics"
                    price="Free"
                    isDisabled={isActivePremium}
                    isSelected={selectedPlan === PlanType.FREE}
                    onSelect={() => setSelectedPlan(PlanType.FREE)}
                  />
                </Grid>
                <Grid container minHeight="72px" maxHeight="72px">
                  <PlanListItem
                    name="Premium plan"
                    description="Unlock all GTO solutions and step up your game"
                    price={`${premiumPlan?.price / 100}€/month`}
                    isSelected={selectedPlan === PlanType.PREMIUM}
                    onSelect={() => setSelectedPlan(PlanType.PREMIUM)}
                  />
                </Grid>
                {isActivePremium ? (
                  activeSubscription.cancelled ? (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      mt={1}
                    >
                      <Box mb={1}>
                        <Text color={COLORS.ERROR} fontSize="0.9em">
                          {`Your subscription will be stopped on ${moment(
                            activeSubscription.subscriptionEndDate
                          ).format("MM/DD/yyyy")}`}
                        </Text>
                      </Box>
                      <Box>
                        <ButtonRounded
                          borderColor={COLORS.ERROR}
                          color={COLORS.ERROR}
                          fullWidth={false}
                          label="I changed my mind"
                          labelColor={COLORS.WHITE}
                          labelUppercased={false}
                          onClick={handleSubscriptionCancellation}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="flex-end" mt={1}>
                      <Button
                        onClick={openModal}
                        variant="text"
                        style={{
                          color: COLORS.ERROR,
                          fontSize: "0.9em",
                          maxWidth: "fit-content",
                          textAlign: "right",
                          textDecoration: "underline",
                          textTransform: "unset",
                        }}
                      >
                        End my membership
                      </Button>
                    </Box>
                  )
                ) : isError ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt={1}
                  >
                    <Box mb={1}>
                      <Text color={COLORS.ERROR}>
                        There was a problem with your payment.
                      </Text>
                    </Box>
                    <Box>
                      <Link to={CONTACT_US}>
                        <ButtonRounded
                          label="Contact us"
                          onClick={() => {}}
                          color={COLORS.ERROR}
                        />
                      </Link>
                    </Box>
                  </Box>
                ) : (
                  selectedPlan === "PREMIUM" && (
                    <>
                      <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Box mr={2}>
                          <ButtonRounded
                            borderColor={COLORS.ACCENT_1}
                            color="transparent"
                            fullWidth={false}
                            label="Cancel"
                            labelColor={COLORS.ACCENT_1}
                            labelUppercased={false}
                            onClick={() => setSelectedPlan(PlanType.FREE)}
                          />
                        </Box>
                        <ButtonRounded
                          color={COLORS.ACCENT_1}
                          fullWidth={false}
                          label="Subscribe"
                          labelUppercased={false}
                          onClick={() =>
                            window.open(activeSubscription.quickpayLink)
                          }
                        />
                      </Box>
                      <Box display="flex" justifyContent="flex-end" mt={1}>
                        <Text color={COLORS.SECONDARY_TEXT} variant="subtitle2">
                          {`You will billed ${
                            premiumPlan?.price / 100
                          }€/month starting from today`}
                        </Text>
                      </Box>
                    </>
                  )
                )}
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          {isActivePremium && (
            <Box display="flex" flexDirection="column" px={4}>
              <Box mb={5}>
                <Text color={COLORS.TITLE_1} fontSize="22px">
                  Payment method
                </Text>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                bgcolor={COLORS.BLUE_2}
                borderRadius="8px"
                padding="8px"
                minHeight="72px"
                maxHeight="72px"
                color={COLORS.WHITE}
              >
                <Box>
                  <img src={bankCard} alt="Bank card logo" />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Box>
                    <Text fontSize="18px">
                      {`**** **** **** ${activeSubscription?.quickpayMetadata?.last4}`}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="12px">
                      Exp. {activeSubscription?.quickpayMetadata?.exp_month}/
                      {activeSubscription?.quickpayMetadata?.exp_year}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
