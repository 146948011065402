import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";

import Text from "ui/Text";
import TextLink from "ui/TextLink";
import {
  ACTIONS_COLORS,
  COLORS,
  COLOR_VARIANTS,
  ColorVariants,
} from "utils/theme";

import themeSelectorCloseGif from "assets/images/theme_selector_close.gif";
import themeSelectorOpenGif from "assets/images/theme_selector_open.gif";

type ThemeItemProps = {
  isSelected: boolean;
  label: string;
  onSelectColorVariant: () => void;
  value: ColorVariants;
};

const useRadioStyles = makeStyles({
  checked: {
    color: COLORS.ACCENT_1,
  },
  root: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04);",
    },
  },
});

const ThemeItem: React.FC<ThemeItemProps> = ({
  isSelected,
  label,
  onSelectColorVariant,
  value,
}) => {
  const radioClasses = useRadioStyles();

  const theme = ACTIONS_COLORS[value];
  return (
    <Box
      onClick={onSelectColorVariant}
      sx={{
        alignItems: "center",
        bgcolor: isSelected ? COLORS.BACKGROUND_2 : COLORS.BACKGROUND_1,
        borderRadius: "8px",
        cursor: "pointer",
        display: "flex",
        height: "56px",
        mb: "16px",
        px: "16px",
      }}
    >
      <Radio
        checked={isSelected}
        classes={radioClasses}
        color="default"
        value={value}
      />
      <Box sx={{ mr: "96px" }}>
        <Text color={COLORS.BLUE_1}>{label}</Text>
      </Box>
      <Box
        sx={{
          borderBottomLeftRadius: "8px",
          borderTopLeftRadius: "8px",
          borderRight: `1px solid ${COLORS.WHITE}`,
          bgcolor: theme["ALL_IN"],
          height: "16px",
          width: "16px",
        }}
      />
      {(theme["RAISES"] as string[]).map((raiseColor) => (
        <Box
          key={`theme-item-${value}-${raiseColor}`}
          sx={{
            borderRight: `1px solid ${COLORS.WHITE}`,
            bgcolor: raiseColor,
            height: "16px",
            width: "16px",
          }}
        />
      ))}
      <Box
        sx={{
          borderRight: `1px solid ${COLORS.WHITE}`,
          bgcolor: theme["CALL"],
          height: "16px",
          width: "16px",
        }}
      />
      <Box
        sx={{
          borderBottomRightRadius: "8px",
          borderTopRightRadius: "8px",
          bgcolor: theme["FOLD"],
          height: "16px",
          width: "16px",
        }}
      />
    </Box>
  );
};

type Props = {
  colorVariant: ColorVariants;
  onSelectColorVariant: (variant: ColorVariants) => void;
};

const ThemeSelector: React.FC<Props> = ({
  colorVariant,
  onSelectColorVariant,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        className="theme-picker-tour"
        onClick={handlePopoverOpen}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{ alignItems: "center", cursor: "pointer", display: "flex" }}
      >
        <img
          alt=""
          src={isHovered ? themeSelectorOpenGif : themeSelectorCloseGif}
          style={{
            height: "64px",
            width: "64px",
          }}
        />
        <TextLink color={COLORS.WHITE}>Change theme</TextLink>
      </Box>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <Box sx={{ pt: "24px", pb: "12px", px: "24px", textAlign: "center" }}>
          {COLOR_VARIANTS.map(({ label, value }) => (
            <ThemeItem
              isSelected={colorVariant === value}
              key={`theme-item-${value}`}
              label={label}
              onSelectColorVariant={() =>
                onSelectColorVariant(value as ColorVariants)
              }
              value={value}
            />
          ))}
          <Box onClick={handlePopoverClose}>
            <TextLink color={COLORS.BLUE_2}>Close</TextLink>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ThemeSelector;
