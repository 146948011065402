import React from "react";
import Box from "@material-ui/core/Box";

import Text from "ui/Text";
import { COLORS } from "utils/theme";

import wipSvg from "assets/images/wip.svg";

type Props = {
  isSmall?: boolean;
};

const WIP: React.FC<Props> = ({ isSmall = false }) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        color: COLORS.WHITE,
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        width: isSmall ? "75%" : "30%",
      }}
    >
      <img alt="" src={wipSvg} style={{ marginBottom: "24px" }} />
      <Box sx={{ mb: "24px" }}>
        <Text fontFamily="Typo Round Regular" fontSize="22px">
          Work in progress
        </Text>
      </Box>
      <Box sx={{ mb: "24px" }}>
        <Text>
          FLOP GTO is not available on tablets and mobile phones yet. We are
          making our best to bring it to these platforms
        </Text>
      </Box>
      <Box sx={{ mb: "24px" }}>
        <Text>
          If this message is displaying on Desktop mode, please maximize the
          current window.
        </Text>
      </Box>
    </Box>
  );
};

export default WIP;
