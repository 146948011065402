import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SignUpPayload } from "api";
import useQuery from "hooks/useQuery";
import { signUp } from "store/account";
import { PlanType } from "types";
import PlanSelector from "./components/PlanSelector_large";
import PlanSelectorFoldable from "./components/PlanSelector_foldable";
import SignupScreen from "./components/Signup";
import { Box } from "@material-ui/core";
import flopLogo2 from "../../assets/images/flop_gto_logo_2.svg";
import Text from "../../ui/Text";
import { COLORS } from "../../utils/theme";
import UnloggedWrapper from "../../components/UnloggedWrapper";

export default function Signup() {
  const query = useQuery();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [selectedPackage, selectPackage] = useState(
    query.get("type") === "premium" ? PlanType.PREMIUM : PlanType.FREE
  );

  function setFirstStep() {
    setStep(1);
  }

  function selectPlan(plan: PlanType) {
    selectPackage(plan);
    setStep(2);
  }

  function createAccount(payload: Pick<SignUpPayload, "email" | "password">) {
    dispatch(
      signUp({
        ...payload,
        originalChosenPackage: selectedPackage,
      })
    );
  }

  if (step === 1) {
    return (
      <UnloggedWrapper>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
            <img src={flopLogo2} alt="flop gto logo" width={180} />
            <Text
              color={COLORS.WHITE}
              fontFamily="Typo Round Regular"
              fontSize="24px"
            >
              (1/2) Choose your plan
            </Text>
          </Box>
          {query.get("type") ? (
            <PlanSelectorFoldable
              defaultValue={selectedPackage}
              onPlanSelect={selectPlan}
            />
          ) : (
            <PlanSelector onPlanSelect={selectPlan} />
          )}
        </Box>
      </UnloggedWrapper>
    );
  }

  return <SignupScreen createAccount={createAccount} onBack={setFirstStep} />;
}
