import React, { useState } from "react";
import { upperFirst } from "lodash/fp";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Close from "@material-ui/icons/Close";

import { ActionIcon } from "components/ActionsBar";
import Text from "ui/Text";
import { Action } from "utils/models";
import { COLORS, getActionLabel } from "utils/theme";

type Props = {
  availableActions: Action[];
  isPlaying?: boolean;
  label: string;
  onCancelAction: () => void;
  onSelectAction: (branchId: number) => void;
  pastActions: { action: string; actionValueInBb: number; branchId: number }[];
  stack: number;
  sublabel?: string;
};

const PlayerPositionCard: React.FC<Props> = ({
  availableActions,
  isPlaying = false,
  label,
  onCancelAction,
  onSelectAction,
  pastActions,
  stack,
  sublabel,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const lastAction = pastActions.slice(-1)[0]?.action;

  const colors = isPlaying
    ? {
        border: COLORS.WHITE,
        card: COLORS.BLUE_3,
      }
    : lastAction === "FOLD"
    ? {
        border: COLORS.PRIMARY,
        card: COLORS.SECONDARY,
        text: COLORS.PRIMARY,
      }
    : {
        border: COLORS.MUTED_PRIMARY,
        card: COLORS.PRIMARY,
      };

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{ position: "relative" }}
    >
      <Card
        sx={{
          ...(isPlaying && { border: `2px solid ${COLORS.WHITE}` }),
          ...(isHovered && {
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
          }),
          bgcolor: colors.card,
          display: "flex",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            borderRight: `1px solid ${colors.border}`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: "3vw",
            width: "3vw",
          }}
        >
          <Text color={colors.text} fontSize="1vw">
            {label}
          </Text>
          {sublabel && (
            <Text color={colors.text} fontSize="0.75vw">
              {sublabel}
            </Text>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "4.5vw",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              borderBottom: `1px solid ${colors.border}`,
              display: "flex",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <Text color={colors.text} fontSize="0.75vw">
              {stack}
            </Text>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {isPlaying && <Text fontSize="0.75vw">Playing...</Text>}
            {!!pastActions.length &&
              pastActions
                .slice()
                .reverse()
                .map(({ action, actionValueInBb }) =>
                  getActionLabel(action, actionValueInBb)
                )
                .map((action, index) => (
                  <Text
                    color={colors.text}
                    fontSize="0.75vw"
                    key={`action-${action}-${index}`}
                  >
                    {upperFirst(action.toLowerCase())}
                  </Text>
                ))}
            {!isPlaying && !pastActions.length && (
              <Text fontSize="0.75vw">Waiting</Text>
            )}
          </Box>
        </Box>
      </Card>
      {!!pastActions.length && isHovered && (
        <Box
          onClick={(e) => {
            e.stopPropagation();
            onCancelAction();
          }}
          sx={{
            borderRadius: "0.83vw",
            bgcolor: COLORS.ERROR,
            color: COLORS.WHITE,
            cursor: "pointer",
            height: "1.25vw",
            position: "absolute",
            right: "-0.62vw",
            top: "-0.62vw",
            width: "1.25vw ",
          }}
        >
          <Close style={{ fontSize: "1.25vw" }} />
        </Box>
      )}
      {isPlaying && isHovered && (
        <Box
          sx={{
            borderBottomLeftRadius: "0.2vw",
            borderBottomRightRadius: "0.2vw",
            boxShadow:
              "0px 0.05vw 0.05vw rgb(0 0 0 / 15%), 0px 0.05vw 0.1vw rgb(0 0 0 / 15%)",
            bgcolor: COLORS.PRIMARY,
            color: COLORS.WHITE,
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "100%",
            width: "100%",
            zIndex: 999,
          }}
        >
          {availableActions.map(
            ({ action, actionValueInBb, branchId }, index) => (
              <Box
                key={`action-${branchId}-${action}`}
                onClick={() => {
                  setIsHovered(false);
                  onSelectAction(branchId);
                }}
                sx={{
                  alignItems: "center",
                  ...(index !== availableActions.length - 1 && {
                    borderBottom: "1px solid #454F57",
                  }),
                  display: "flex",
                  p: "0.42vw",
                }}
              >
                <Box sx={{ display: "flex", mr: "0.2vw" }}>
                  <ActionIcon action={action} />
                </Box>
                <Text fontSize="0.75vw">
                  {getActionLabel(action, actionValueInBb)}
                </Text>
              </Box>
            )
          )}
        </Box>
      )}
    </Box>
  );
};

export default PlayerPositionCard;
