import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import { PlanType } from "types";
import { LOGIN } from "constants/routes";
import ButtonRounded from "ui/ButtonRounded";
import Text from "ui/Text";
import { COLORS } from "utils/theme";

export interface PlanSelectorProps {
  onPlanSelect: any;
}

export default function PlanSelector({ onPlanSelect }: PlanSelectorProps) {
  function selectFree() {
    onPlanSelect(PlanType.FREE);
  }

  function selectPremium() {
    onPlanSelect(PlanType.PREMIUM);
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="stretch" mb={2}>
        <Box mr={4}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            bgcolor={COLORS.PRIMARY}
            borderRadius="16px"
            width={404}
            height="100%"
            px={4}
            py={2}
          >
            <Box mb={6}>
              <Box textAlign="center" mb={2}>
                <Text
                  color={COLORS.WHITE}
                  component="h4"
                  fontSize="24px"
                  fontWeight="bold"
                >
                  Free
                </Text>
              </Box>
              <Box textAlign="center">
                <Text color={COLORS.WHITE} fontSize="18px">
                  Free forever. Get started and discover the basics of GTO.
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mb={6}
            >
              <Box display="flex" mb={2}>
                <Box mr={1} color={COLORS.GREEN}>
                  <Check />
                </Box>
                <Text color={COLORS.WHITE} fontSize="18px">
                  MTT 9 Max (preflop) - 10, 12 & 15 bbs
                </Text>
              </Box>
              <Box display="flex" mb={2}>
                <Box mr={1} color={COLORS.GREEN}>
                  <Check />
                </Box>
                <Text color={COLORS.WHITE} fontSize="18px">
                  MTT 6 Max (preflop) - 10, 12 & 15 bbs
                </Text>
              </Box>
              <Box display="flex" mb={2}>
                <Box mr={1} color={COLORS.GREEN}>
                  <Check />
                </Box>
                <Text color={COLORS.WHITE} fontSize="18px">
                  Spin Full (preflop) - 8 & 10 bbs
                </Text>
              </Box>
              <Box display="flex" mb={2}>
                <Box mr={1} color={COLORS.GREEN}>
                  <Check />
                </Box>
                <Text color={COLORS.WHITE} fontSize="18px">
                  Spins Simple (preflop) - 8 & 10 bbs
                </Text>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box width={175} mb={1}>
                <ButtonRounded
                  borderColor={COLORS.BUTTON_PRIMARY}
                  label="Get started"
                  labelBold
                  labelColor={COLORS.BUTTON_PRIMARY}
                  labelUppercased
                  onClick={selectFree}
                />
              </Box>
              <Box textAlign="center">
                <Text color={COLORS.BLUE_6}>0 € / month</Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          bgcolor={COLORS.PRIMARY}
          borderRadius="16px"
          width={404}
          px={4}
          py={2}
        >
          <Box mb={6}>
            <Box textAlign="center" mb={2}>
              <Text
                color={COLORS.WHITE}
                component="h4"
                fontSize="24px"
                fontWeight="bold"
              >
                Premium
              </Text>
            </Box>
            <Box textAlign="center">
              <Text color={COLORS.WHITE} fontSize="18px">
                Unlock all GTO solutions available and step up your game.
              </Text>
            </Box>
          </Box>
          <Box textAlign="center" mb={2}>
            <Text color={COLORS.WHITE} fontSize="18px">
              <i>Includes everything from the free plan plus:</i>
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mb={6}
          >
            <Box display="flex" mb={2}>
              <Box mr={1} color={COLORS.GREEN}>
                <Check />
              </Box>
              <Text color={COLORS.WHITE} fontSize="18px">
                MTT 9 Max (preflop) - 17, 20, 25, 30, 40, 50, 60 & 80 bbs
              </Text>
            </Box>
            <Box display="flex" mb={2}>
              <Box mr={1} color={COLORS.GREEN}>
                <Check />
              </Box>
              <Text color={COLORS.WHITE} fontSize="18px">
                MTT 6 Max (preflop) - 17, 20, 25, 40, 50, 60 & 80 bbs
              </Text>
            </Box>
            <Box display="flex" mb={2}>
              <Box mr={1} color={COLORS.GREEN}>
                <Check />
              </Box>
              <Text color={COLORS.WHITE} fontSize="18px">
                MTT BvB with limps (6-max) : 10 - 12 & 15 bb
              </Text>
            </Box>
            <Box display="flex" mb={2}>
              <Box mr={1} color={COLORS.GREEN}>
                <Check />
              </Box>
              <Text color={COLORS.WHITE} fontSize="18px">
                MTT BvB with limps (9-max) : 10 - 12 - 15 - 17 - 20 - 25 - 30 -
                40 - 50 - 60 - 80 & 100 bb
              </Text>
            </Box>
            <Box display="flex" mb={2}>
              <Box mr={1} color={COLORS.GREEN}>
                <Check />
              </Box>
              <Text color={COLORS.WHITE} fontSize="18px">
                Spin Full (preflop) - 15, 17, 20 & 25 bbs
              </Text>
            </Box>
            <Box display="flex" mb={2}>
              <Box mr={1} color={COLORS.GREEN}>
                <Check />
              </Box>
              <Text color={COLORS.WHITE} fontSize="18px">
                Spin Simple (preflop) - 15, 17, 20 & 25 bbs
              </Text>
            </Box>
            <Box display="flex" mb={2}>
              <Box mr={1} color={COLORS.GREEN}>
                <Check />
              </Box>
              <Text color={COLORS.WHITE} fontSize="18px">
                Cash Game NL100 (preflop) - 100bbs
              </Text>
            </Box>
            <Box display="flex" mb={2}>
              <Box mr={1} color={COLORS.GREEN}>
                <Check />
              </Box>
              <Text color={COLORS.WHITE} fontSize="18px">
                Cash Game NL200 (preflop) - 100bbs
              </Text>
            </Box>
            <Box display="flex">
              <Box mr={1} color={COLORS.GREEN}>
                <Check />
              </Box>
              <Text color={COLORS.WHITE} fontSize="18px">
                Cash Game NL500 (preflop) - 100bbs
              </Text>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box width={175} mb={1}>
              <ButtonRounded
                borderColor={COLORS.BUTTON_PRIMARY}
                color={COLORS.BUTTON_PRIMARY}
                label="GO PREMIUM"
                labelBold
                labelColor={COLORS.WHITE}
                onClick={selectPremium}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Text color={COLORS.BLUE_6}>30 € / month (no commitment)</Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box width="fit-content">
          <Link to={LOGIN}>
            <ButtonRounded
              label="Back to login"
              borderColor={COLORS.ERROR}
              color={COLORS.TRANSPARENT}
              labelColor={COLORS.ERROR}
              onClick={() => {}}
            />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
