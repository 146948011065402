import axios from "axios";
import { PlanType } from "types";
import {
  Account,
  Action,
  GameState,
  Hand,
  Profile,
  Solution,
  StrategyOverview,
} from "utils/models";

type Credentials = {
  email: string;
  password: string;
};

export type SignInPayload = Credentials & {};

export type SignUpPayload = Credentials & {
  originalChosenPackage: PlanType;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_GTO_BASE_API_URL,
});

const setBearer = ({ access_token }: Account) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
};

const downgradeToFree = async (userId: string) => {
  const response = await api.put(
    `accounts/${userId}/downgrade-choice-freemium`
  );
  return response.data;
};

const getPaymentPlans = async () => {
  const response = await api.get("payment/plans/");
  return response.data;
};

const getFoldToAction = async (
  solutionId: number,
  foldCount: number,
  branchId?: number
) => {
  const response = await api.get<number[]>(
    `game-state/${solutionId}/fold/${foldCount}/${branchId ?? ""}`
  );
  return response.data;
};

const getSolutions = async () => {
  const response = await api.get<Solution[]>("solutions");
  return response.data;
};

const getSolutionActions = async (solutionId: number, branchId?: number) => {
  try {
    const response = await api.get<Action[]>(
      `game-state/${solutionId}/actions/${branchId ?? ""}`
    );
    return response.data;
  } catch (e) {
    // FIXME: we could override interceptors to avoid this
    if (e.response.status === 404) {
      return [];
    }
    throw e.response?.data || "An error occured";
  }
};

const getSolutionGameState = async (solutionId: number, branchId?: number) => {
  const response = await api.get<GameState>(
    `game-state/${solutionId}/state/${branchId ?? ""}`
  );
  return response.data;
};

const getStrategiesHands = async (solutionId: number, branchId?: number) => {
  const response = await api.get<Hand[]>(
    `resolution/${solutionId}/strategies/hands/${branchId ?? ""}`
  );
  return response.data;
};

const getStrategiesOverview = async (solutionId: number, branchId?: number) => {
  try {
    const response = await api.get<StrategyOverview[]>(
      `resolution/${solutionId}/strategies/overview/${branchId ?? ""}`
    );
    return response.data;
  } catch (e) {
    if (e.response.status === 404) {
      throw Error("ERR_SOLUTION_FINISHED");
    }
    return [];
  }
};

const postContactUs = async (
  authorEmail: string,
  subject: string,
  content: string
) => {
  try {
    const response = await api.post("accounts/contact-us", {
      authorEmail,
      subject,
      content,
    });
    return response.data;
  } catch (e) {
    throw e.response?.data || "An error occured";
  }
};

const postLogout = async (refreshToken: string) => {
  try {
    const response = await api.post<Account>("accounts/logout", {
      refresh_token: refreshToken,
    });
    delete api.defaults.headers["Authorization"];
    return response.data;
  } catch (e) {
    throw e.response?.data || "An error occured";
  }
};

const postRefreshToken = async (userId: string, refreshToken: string) => {
  try {
    const response = await api.post<Account>(
      `accounts/${userId}/refresh-token`,
      {
        refresh_token: refreshToken,
      }
    );
    setBearer(response.data);
    return response.data;
  } catch (e) {
    delete api.defaults.headers["Authorization"];
    throw e.response?.data || "An error occured";
  }
};

const postResetPasswordRequest = async (email: string) => {
  try {
    const response = await api.post(`accounts/reset-password/request/${email}`);
    return response.data;
  } catch (e) {
    throw e.response?.data || "An error occured";
  }
};

const postSignIn = async (email: string, password: string) => {
  try {
    const response = await api.post<Account>("accounts/sign-in", {
      email,
      password,
    });
    setBearer(response.data);
    return response.data;
  } catch (e) {
    throw e.response
      ? {
          code: e.response.data.errorCode,
          message: e.response.data.message,
        }
      : "An error occured";
  }
};

const signUp = async (payload: SignUpPayload) => {
  try {
    const response = await api.post<Account>("accounts/sign-up", payload);
    setBearer(response.data);
    return response.data;
  } catch (e) {
    throw e.response?.data || "An error occured";
  }
};

const putNewPassword = async (userId: string, newPassword: string) => {
  try {
    const response = await api.put(`accounts/${userId}/password`, {
      newPassword,
    });
    return response.data;
  } catch (e) {
    throw e.response?.data || "An error occured";
  }
};

const getProfile = async (userId: string) => {
  try {
    // TODO: make one call (ask backend)
    const response1 = await api.get<Profile>(`profiles/${userId}`);
    const response2 = await api.get<Profile>(`accounts/${userId}`);

    return {
      ...response1.data,
      ...response2.data,
    };
  } catch (e) {
    throw e.response?.data || "An error occured";
  }
};

const getPaymentMethods = async () => {
  try {
    const response = await api.get("payment/method/");
    return response.data;
  } catch (e) {
    throw e.response?.data || "An error occured";
  }
};

const postPaymentSubmit = async (stateData: any) => {
  try {
    const response = await api.post("payment/method/submit", {
      currency: "EUR",
      value: 10,
      ...stateData,
    });
    return response.data;
  } catch (e) {
    throw e.response?.data || "An error occured";
  }
};

async function cancelSubscription(subscriptionId: string) {
  const response = await api.post(
    `payment/subscriptions/${subscriptionId}/cancel`
  );

  return response.data;
}

async function changeEmail(payload: { newEmail: string; oldEmail: string }) {
  const response = await api.post("accounts/change-email", payload);

  return response.data;
}

async function stopSubscriptionCancellation(subscriptionId: string) {
  const response = await api.post(
    `payment/subscriptions/${subscriptionId}/uncancel`
  );

  return response.data;
}

async function createUserSubscription(planId: any) {
  const response = await api.put("payment/subscriptions/", {
    planId,
  });

  return response.data;
}

async function getUserSubscription(userId: string, planId: string) {
  const response = await api.get(
    `payment/subscriptions/${userId}/plan/${planId}/latest`
  );

  return response.data;
}

async function resendActivationEmail(payload: {
  email: string;
  userId: string;
}) {
  const response = await api.post("accounts/verify-email/request", payload);

  return response.data;
}

const updateProfile = async (
  userId: string,
  email: string,
  firstName?: string,
  lastName?: string
) => {
  try {
    const response = await api.put(`profiles/${userId}`, {
      email,
      firstName,
      lastName,
    });
    return response.data;
  } catch (e) {
    throw e.response?.data || "An error occured";
  }
};

const API = {
  cancelSubscription,
  changeEmail,
  createUserSubscription,
  downgradeToFree,
  getFoldToAction,
  getPaymentPlans,
  getProfile,
  getSolutions,
  getSolutionActions,
  getSolutionGameState,
  getStrategiesHands,
  getStrategiesOverview,
  getUserSubscription,
  postContactUs,
  postLogout,
  postRefreshToken,
  postResetPasswordRequest,
  postSignIn,
  putNewPassword,
  getPaymentMethods,
  postPaymentSubmit,
  resendActivationEmail,
  signUp,
  stopSubscriptionCancellation,
  updateProfile,
};

export default API;
