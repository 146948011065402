import React, { useState } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import { accountSelector } from "store/account";
import ButtonRounded from "ui/ButtonRounded";
import Text from "ui/Text";
import { COLORS } from "utils/theme";
import API from "api";
import discordPng from "assets/icons/discord.png";
import { Link } from "@material-ui/core";

type Props = {};

const CONTACT_REASONS = [
  {
    label: "Feedback & suggestions",
    value: "feedback",
  },
  {
    label: "Feature request",
    value: "feature",
  },
  {
    label: "Need some help",
    value: "help",
  },
  {
    label: "Report a bug",
    value: "bug",
  },
  {
    label: "Something else",
    value: "else",
  },
];

const useSelectStyles = makeStyles({
  iconFilled: {
    height: "1.25vw",
    right: "0.36vw",
    top: "calc(50% - 0.62vw)",
    width: "1.25vw",
  },
  root: {
    alignItems: "center",
    color: COLORS.PRIMARY,
    display: "flex",
    height: "2.1vw",
    lineHeight: "0.94vw",
    padding: "0px",
    paddingLeft: "0.83vw",
    width: "10.4vw",
  },
  select: {
    backgroundColor: COLORS.BLUE_5,
    "&:focus": {
      backgroundColor: COLORS.BLUE_5,
    },
  },
});

const useMenuStyles = makeStyles({
  paper: {
    backgroundColor: COLORS.BLUE_5,
  },
  list: {
    color: COLORS.PRIMARY,
  },
});

const useTextAreaStyles = makeStyles({
  root: {
    width: "50vw",
    "& .MuiOutlinedInput-input": {
      color: COLORS.BLACK,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.BLUE_6,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.BLUE_6,
    },
  },
});

const ContactUs: React.FC<Props> = () => {
  const menuClasses = useMenuStyles();
  const selectClasses = useSelectStyles();
  const textAreaClasses = useTextAreaStyles();

  const { email } = useSelector(accountSelector);

  const [contactReason, setContactReason] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const sendMessage = async () => {
    setIsLoading(true);
    try {
      await API.postContactUs(email, contactReason, message);
      setContactReason("");
      setMessage("");
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          bgcolor: COLORS.PRIMARY,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            backgroundColor: COLORS.SECONDARY,
            marginTop: "30px",
            marginBottom: "20px",
            maxWidth: "450px",
          }}
        >
          <Box
            sx={{
              color: COLORS.WHITE,
              my: 3,
              mx: 3,
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <Text>
              Want quick answers and the possibility to talk GTO with other
              players or the FLOP GTO Team ?
            </Text>
            <Link
              href="https://discord.gg/8Yr8BCbuqm"
              target="_blank"
              rel="noopener"
              underline="none"
              style={{
                color: COLORS.WHITE,
              }}
            >
              {/* <a href="https://discord.gg/8Yr8BCbuqm" target="_blank"> */}
              <Box
                justifyContent="center"
                display="flex"
                flexDirection="row"
                alignItems="center"
                marginTop="10px"
              >
                <img
                  alt="Discord logo"
                  src={discordPng}
                  style={{
                    pointerEvents: "all",
                    width: "40px",
                    paddingRight: "10px",
                  }}
                />
                <Text>Join our discord server now!</Text>
              </Box>
            </Link>
            {/* </a> */}
          </Box>
        </Card>
        <Card>
          <Box sx={{ px: "96px", py: " 64px" }}>
            <Box sx={{ mb: "16px" }}>
              <Text color={COLORS.TITLE_1} fontSize="22px">
                Contact Us
              </Text>
            </Box>
            <Box sx={{ mb: "40px" }}>
              <Text color={COLORS.BLACK}>
                A question, some feedback, or just want to say “Hi”?
                <br />
                You’ve come to the right place!
              </Text>
            </Box>
            <Box sx={{ mb: "28px" }}>
              <Text color={COLORS.BLUE_3}>Subject</Text>
              <Select
                classes={selectClasses}
                disableUnderline
                MenuProps={{
                  classes: menuClasses,
                  elevation: 0,
                  MenuListProps: { disablePadding: true },
                }}
                onChange={({ target: { value } }) => setContactReason(value)}
                variant="filled"
                value={contactReason}
              >
                {CONTACT_REASONS.map(({ label, value }) => (
                  <MenuItem key={`contact_reason_${value}`} value={value}>
                    <Box
                      sx={{
                        fontSize: "0.85vw",
                        "&:focus": { outline: "none" },
                      }}
                    >
                      {label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ mb: "8px" }}>
              <Text color={COLORS.BLUE_3}>Your message</Text>
            </Box>
            <Box sx={{ mb: "24px" }}>
              <TextField
                classes={textAreaClasses}
                multiline
                onChange={({ target: { value } }) => setMessage(value)}
                rows={10}
                value={message}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <ButtonRounded
                color={COLORS.ACCENT_1}
                disabled={!contactReason || !message}
                fullWidth={false}
                isLoading={isLoading}
                label="Send message"
                labelUppercased={false}
                onClick={sendMessage}
              />
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default ContactUs;
