import React, { useCallback, useState } from "react";
import Tour from "reactour";
import Cookies from "js-cookie";

import { COLORS } from "utils/theme";

type Props = {
  closeGTOTour: () => void;
  isGTOTourOpened: boolean;
};

const steps = [
  {
    selector: ".game-type-tour",
    content: "Select the game type you want to use for the simulation",
  },
  {
    selector: ".game-format-tour",
    content: "Select the game format you want to use for the simulation.",
  },
  {
    selector: ".stack-value-tour",
    content:
      "Use the slider to choose the starting stack size (in big blinds) to use for the simulation.",
  },
  {
    selector: ".player-card-tour",
    content:
      "Select any seat on the table and choose an action to perform among the possible ones. All the previous seats that have not played will be folded automatically.",
  },
  {
    selector: ".actions-bar-tour",
    content: "Pick the action you want to perform for the selected player.",
  },
  {
    selector: ".branch-traveler-tour",
    content: "Undo, redo an action, or reset the simulation to run a new one.",
  },
  {
    selector: ".theme-picker-tour",
    content:
      "Don’t like the range grid color pattern? You can pick another one here.",
  },
  {
    selector: ".weight-switcher-tour",
    content:
      "Have difficulty visualizing the range? Display strategy only to make each hand decision clearer.",
  },
  {
    selector: ".hand-strategy-tour",
    content:
      "In the main grid you can see the GTO strategy for any hand in your range and get more information such as EV when hovering over a specific hand.",
  },
];

const GTOTour: React.FC<Props> = ({ closeGTOTour, isGTOTourOpened }) => {
  const hasSeenTour = Cookies.get("has_seen_tour") === "true";

  const [isTourOpen, setIsTourOpen] = useState(!hasSeenTour);

  const onCloseTour = useCallback(() => {
    closeGTOTour();
    setIsTourOpen(false);
    Cookies.set("has_seen_tour", "true");
  }, []);

  return (
    <Tour
      accentColor={COLORS.ACCENT_1}
      steps={steps}
      isOpen={isGTOTourOpened || isTourOpen}
      onRequestClose={onCloseTour}
      rounded={16}
    />
  );
};

export default GTOTour;
