import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import Cookies from "js-cookie";
import API, { SignInPayload, SignUpPayload } from "api";
import { State } from "store";
import { ROOT } from "constants/routes";
import { Account } from "utils/models";
import mixpanel from "mixpanel-browser";
import { EVENTS } from "../constants/tracking";

export type AccountState = Account & {
  email: string;
  emailVerified?: boolean;
  originalChosenPackage?: string;
};

type RefreshTokenArgs = {
  refresh_token: string;
  userId: string;
};

let refreshTokenTimer: NodeJS.Timeout;

const setAndRefreshToken = ({
  access_token,
  expires_in,
  refresh_expires_in,
  refresh_token,
  userId,
}: Account) => (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  Cookies.set("access_token", access_token, {
    expires: new Date(new Date().getTime() + expires_in * 1000),
  });
  Cookies.set("refresh_token", refresh_token, {
    expires: new Date(new Date().getTime() + refresh_expires_in * 1000),
  });
  Cookies.set("userId", userId);
  refreshTokenTimer = setTimeout(
    () =>
      dispatch(
        refreshToken({
          refresh_token,
          userId,
        })
      ),
    (expires_in - 30) * 1000
  );
};

const clearTokenCookiesAndTimer = () => {
  clearTimeout(refreshTokenTimer);
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  Cookies.remove("userId");
};

export const fetchProfile = createAsyncThunk(
  "fetchProfile",
  async (userId: string) => API.getProfile(userId)
);

export const logout = createAsyncThunk("logout", async (_, { getState }) => {
  const { refresh_token } = accountSelector(getState() as State);
  await API.postLogout(refresh_token);
  clearTokenCookiesAndTimer();
});

export const refreshToken = createAsyncThunk(
  "refreshToken",
  async ({ refresh_token, userId }: RefreshTokenArgs, { dispatch }) => {
    try {
      const account = await API.postRefreshToken(userId, refresh_token);

      mixpanel.identify(account.userId);
      dispatch(setAndRefreshToken(account));
      return account;
    } catch {
      clearTokenCookiesAndTimer();
      throw Error();
    }
  }
);

export const signIn = createAsyncThunk(
  "signIn",
  async ({ email, password }: SignInPayload, { dispatch, getState }) => {
    const account = await API.postSignIn(email, password);

    mixpanel.identify(account.userId);
    mixpanel.people.set({
      email,
    });
    dispatch(setAndRefreshToken(account));
    mixpanel.track(EVENTS.LOGIN);

    const redirectedFromLocation = (getState() as State).router?.location?.state
      ?.from?.pathname;

    dispatch(push(redirectedFromLocation || ROOT));

    return account;
  }
);

export const signUp = createAsyncThunk(
  "signUp",
  async (payload: SignUpPayload, { dispatch }) => {
    const account = await API.signUp(payload);
    dispatch(setAndRefreshToken(account));

    return account;
  }
);

const account = createSlice({
  name: "account",
  initialState: {} as AccountState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(logout.fulfilled, () => ({} as AccountState))
      .addCase(refreshToken.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(refreshToken.rejected, () => ({} as AccountState))
      .addCase(signIn.fulfilled, (_, action) => ({
        ...action.payload,
        email: '',
      }))
      .addCase(signUp.fulfilled, (_, action) => ({
        ...action.payload,
        email: action.meta.arg.email,
      }));
  },
});

export const accountSelector = (state: State) => state.account;

export default account;
