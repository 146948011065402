import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

import Text from "ui/Text";
import { StrategyOverview } from "utils/models";
import {
  COLORS,
  ColorVariants,
  getActionColor,
  getActionLabel,
  getRaiseActionColor,
  RAISE_ACTIONS_REGEX,
} from "utils/theme";

type StrategyOverviewFrequencyItemProps = {
  colorVariant: ColorVariants;
  isFirst: boolean;
  isLast: boolean;
  raiseStrategies: string[];
  strategyOverview: StrategyOverview;
};

const usePopoverStyles = makeStyles({
  paper: {
    borderRadius: "0.83vw",
  },
});

const StrategyOverviewFrequencyItem: React.FC<StrategyOverviewFrequencyItemProps> = ({
  colorVariant,
  isFirst,
  isLast,
  raiseStrategies,
  strategyOverview: { action, actionValueInBb, rangeFrequency, rangeId },
}) => {
  const popoverClasses = usePopoverStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const color =
    action.search(RAISE_ACTIONS_REGEX) !== -1
      ? getRaiseActionColor(colorVariant, action, raiseStrategies)
      : getActionColor(colorVariant, action);

  return (
    <>
      <Box
        key={`frequency-bar-${rangeId}`}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          ...(isFirst && {
            borderBottomLeftRadius: "0.6vw",
            borderTopLeftRadius: "0.6vw",
          }),
          ...(isLast && {
            borderBottomRightRadius: "0.6vw",
            borderTopRightRadius: "0.6vw",
          }),
          bgcolor: color,
          flex: rangeFrequency / 100,
          height: "1.2vw",
        }}
      />
      <Popover
        classes={popoverClasses}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        style={{ pointerEvents: "none" }}
      >
        <Box sx={{ px: "0.6vw", py: "0.2vw" }}>
          <Text color={COLORS.SECONDARY} fontSize="0.6vw">
            {getActionLabel(action, actionValueInBb)}
          </Text>
        </Box>
      </Popover>
    </>
  );
};

type Props = {
  colorVariant: ColorVariants;
  raiseStrategies: string[];
  strategiesOverview: StrategyOverview[];
};

const StrategyOverviewFrequencyBar: React.FC<Props> = ({
  colorVariant,
  raiseStrategies,
  strategiesOverview,
}) => {
  const displayedStrategiesOverview = strategiesOverview.filter(
    ({ rangeFrequency }) => !!rangeFrequency
  );

  return (
    <Box sx={{ display: "flex" }}>
      {displayedStrategiesOverview.map((strategyOverview, index) => (
        <StrategyOverviewFrequencyItem
          colorVariant={colorVariant}
          isFirst={index === 0}
          isLast={index === displayedStrategiesOverview.length - 1}
          key={`strategy-overview-item-${index}`}
          raiseStrategies={raiseStrategies}
          strategyOverview={strategyOverview}
        />
      ))}
    </Box>
  );
};

export default StrategyOverviewFrequencyBar;
