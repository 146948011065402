export const ROOT = "/";
export const CONTACT_US = "/contact-us";

export const LOGIN = "/login";

export const SETTINGS = "/settings";
export const SETTINGS_SUBSCRIBE = `${SETTINGS}/subscribe`;

export const SIGNUP = "/signup";

export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
