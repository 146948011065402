import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

import { COLORS } from "utils/theme";
import Text from "./Text";

type Props = {
  children: React.ReactElement | React.ReactElement[];
  closeLabel?: string;
  onClose: () => void;
  onSubmit?: () => void;
  open: boolean;
  submitDisabled?: boolean;
  submitLabel?: string;
  title: string;
};

const useDialogStyles = makeStyles({
  paper: {
    borderRadius: "16px",
  },
});

export default function Modal({
  children,
  closeLabel = "Close",
  onClose,
  onSubmit,
  open,
  submitDisabled = false,
  submitLabel = "Submit",
  title,
}: Props) {
  const dialogClasses = useDialogStyles();

  return (
    <Dialog classes={dialogClasses} onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <Text fontSize="22px" fontWeight={300} color={COLORS.BLUE_3}>
          {title}
        </Text>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{
            color: COLORS.BLUE_2,
            textTransform: "unset",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          {closeLabel}
        </Button>
        {onSubmit && (
          <Button
            disabled={submitDisabled}
            onClick={onSubmit}
            style={{
              color: COLORS.WARNING,
              textTransform: "unset",
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            {submitLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
