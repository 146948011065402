export const DEALER_POSITION = {
  2: {
    left: "0%",
    top: "-108%",
  },
  3: {
    left: "0%",
    top: "-108%",
  },
  6: {
    left: "0%",
    top: "145%",
  },
  9: {
    left: "-35%",
    top: "-10%",
  },
} as { [key: number]: { left: string; top: string } };

type Position = {
  bet?: {
    left: string;
    top: string;
  };
  label: string;
  left: string;
  sublabel?: string;
  top: string;
};

export default {
  2: [
    {
      bet: {
        left: "40%",
        top: "-100%",
      },
      label: "SB",
      left: "42%",
      top: "77%",
    },
    {
      bet: {
        left: "40%",
        top: "160%",
      },
      label: "BB",
      left: "42%",
      top: "1%",
    },
  ],
  3: [
    {
      bet: {
        left: "40%",
        top: "-100%",
      },
      label: "BTN",
      left: "42%",
      top: "77%",
    },
    {
      bet: {
        left: "115%",
        top: "75%",
      },
      label: "SB",
      left: "6%",
      top: "23%",
    },
    {
      bet: {
        left: "-50%",
        top: "80%",
      },
      label: "BB",
      left: "78%",
      top: "23%",
    },
  ],
  6: [
    {
      bet: {
        left: "40%",
        top: "160%",
      },
      label: "BTN",
      left: "42%",
      top: "1%",
    },
    {
      bet: {
        left: "-50%",
        top: "80%",
      },
      label: "SB",
      left: "78%",
      top: "23%",
    },
    {
      bet: {
        left: "-50%",
        top: "-20%",
      },
      label: "BB",
      left: "78%",
      top: "55%",
    },
    {
      bet: {
        left: "40%",
        top: "-100%",
      },
      label: "UTG",
      left: "42%",
      top: "77%",
    },
    {
      bet: {
        left: "115%",
        top: "-20%",
      },
      label: "HJ",
      left: "6%",
      top: "55%",
    },
    {
      bet: {
        left: "115%",
        top: "80%",
      },
      label: "CO",
      left: "6%",
      top: "23%",
    },
  ],
  9: [
    {
      bet: {
        left: "-50%",
        top: "80%",
      },
      label: "BTN",
      left: "78%",
      top: "23%",
    },
    {
      bet: {
        left: "-50%",
        top: "-20%",
      },
      label: "SB",
      left: "78%",
      top: "55%",
    },
    {
      bet: {
        left: "10%",
        top: "-100%",
      },
      label: "BB",
      left: "63%",
      top: "77%",
    },
    {
      bet: {
        left: "40%",
        top: "-100%",
      },
      label: "UTG",
      left: "42%",
      top: "77%",
    },
    {
      bet: {
        left: "50%",
        top: "-100%",
      },
      label: "UTG",
      left: "21%",
      sublabel: "+1",
      top: "77%",
    },
    {
      bet: {
        left: "115%",
        top: "-20%",
      },
      label: "MP",
      left: "6%",
      top: "55%",
    },
    {
      bet: {
        left: "115%",
        top: "75%",
      },
      label: "LJ",
      left: "6%",
      top: "23%",
    },
    {
      bet: {
        left: "40%",
        top: "160%",
      },
      label: "HJ",
      left: "28%",
      top: "1%",
    },
    {
      bet: {
        left: "40%",
        top: "160%",
      },
      label: "CO",
      left: "56%",
      top: "1%",
    },
  ],
} as { [key: number]: Position[] };
